import React, { Fragment } from 'react';
import IndexVegetationForm from '../components/indexVegetation/indexVegetationForm'
const Fertility = (props) => {
  return (
    <Fragment>
      <IndexVegetationForm></IndexVegetationForm>
    </Fragment>
  );
};

export default Fertility;
