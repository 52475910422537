import React, {useState, useEffect} from 'react';
import {Button, Flex, Spinner} from 'bumbag'
import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import {useToasts, Box, Divider} from 'bumbag'
import Select from "react-select";
import InputMask from 'react-input-mask';
import {AsyncStorage} from "AsyncStorage";

import generalClasses from '../../css/General.module.css';
import formClasses from '../../css/PitForm.module.css';


const PitFieldForm = (props) => {
  const [client, setClient] = useState({id: 0});
  const [farm, setFarm] = useState({id: 0});
  const [field, setField] = useState({id: 0});

  const [cropYear, setCropYear] = useState({});

  const [cropHarvest, setCropHarvest] = useState({id: 0});
  const [plantingDateHarvest, setPlantingDateHarvest] = useState('');
  const [targetYieldHarvest, setTargetYieldHarvest] = useState('');
  const [targetYieldExpectedHarvest, setTargetYieldExpectedHarvest] = useState('');
  const [varietyHarvest, setVarietyHarvest] = useState({id: 0});
  const [irrigationHarvest, setIrrigationHarvest] = useState(false);

  const [cropOffSeason, setCropOffSeason] = useState({id: 0});
  const [plantingDateOffSeason, setPlantingDateOffSeason] = useState('');
  const [targetYieldOffSeason, setTargetYieldOffSeason] = useState('');
  const [targetYieldExpectedOffSeason, setTargetYieldExpectedOffSeason] = useState('');
  const [varietyOffSeason, setVarietyOffSeason] = useState({id: 0});
  const [irrigationOffSeason, setIrrigationOffSeason] = useState(false);

  const [optionsCropYear, setOptionsCropYear] = useState(['']);
  const [optionsCropHarvest, setOptionsCropHarvest] = useState([{slug: 0, name: ''}]);
  const [optionsCropOffSeason, setOptionsCropOffseason] = useState([{slug: 0, name: ''}]);
  const [optionsClient, setOptionsClient] = useState([{id: 0, name: ''}]);
  const [optionsFarm, setOptionsFarm] = useState([{id: 0, name: ''}]);
  const [optionsField, setOptionsField] = useState([{id: 0, name: ''}]);
  const [optionsVariety, setOptionsVariety] = useState([{id: 0, name: ''}]);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState({
    assemblyLine: false,
    client: false,
    field: false,
    farm: false,
    cropYear: false,
    variety: false,
    cropOffSeason: false,
    cropHarvest: false
  });
  const [blockFields, setBlockFields] = useState({
    client: false,
    farm: false,
    cropYear: false,
    field: false,
    variety: false,
  });
  const token = localStorage.getItem('token');
  let {assemblyLineId} = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  let pitFarmData = {
    action: 'pitFieldAdd'
  };

  if (queryParams.get('action') === "pitFarmEdit" || queryParams.get('action') === "pitFarmAdd") {
    pitFarmData = {
      action: queryParams.get('action'),
      assemblyLineId: queryParams.get('assemblyLineId'),
      assemblyLinePitFarmId: queryParams.get('assemblyLinePitFarmId'),
      clientId: queryParams.get('clientId'),
      farmerId: queryParams.get('farmerId'),
      farmId: queryParams.get('farmId'),
      fieldId: queryParams.get('fieldId'),
      cropYearArea: queryParams.get('cropYearArea')
    }
  }

  if (assemblyLineId) {
    pitFarmData = {
      action: 'pitFieldEdit',
      assemblyLineId: assemblyLineId
    }
  }
  let history = useHistory();
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  const toasts = useToasts();

  async function fetchCropYear() {
    setLoading({...loading, cropYear: true});
    try {
      const response = await axios.get(`${host}/harvest-years`);
      let cont = 1;
      response.data = response.data.map(elem => {
        let retorno = {
          value: elem,
          label: elem,
        }
        cont++;
        return retorno
      })
      setOptionsCropYear(response.data);
      setLoading({...loading, cropYear: false});
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter os anos safra' });
      setLoading({...loading, cropYear: false});
      console.log(e.response);
    }
  }

  async function fetchClientFarms(clientId) {
    setLoading({...loading, farm: true});
    try {
      const response = await axios.get(`${host}/clients/${clientId}/farms`);
      let cont = 1;
      response.data = response.data.map(elem => {
        let retorno = {
          value: elem.id,
          label: elem.name,
          clientId: clientId,
          ...elem
        }
        cont++;
        return retorno
      })
      setOptionsFarm(response.data);
      setLoading({...loading, farm: false});
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as fazendas' });
      setLoading({...loading, farm: false});
      console.log(e.response);
    }
  }

  async function fetchVariety(varietyId) {
    setLoading({...loading, variety: true});
    try {
      const response = await axios.get(`${host}/culture-varieties`);
      response.data = response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      })
      setOptionsVariety(response.data);
      setLoading({...loading, variety: false});
      if (varietyId) {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].id === varietyId)
            return response.data[i];
        }
      }
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as variedades.' });
      setLoading({...loading, variety: false});
      console.log(e.response);
    }
  }

  async function fetchCrop() {
    try {
      return await axios.get(`${host}/cultures`);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return null;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as culturas' });
      setLoading({...loading, cropHarvest: false});
      console.log(e.response);
      return null;
    }
  }

  async function fetchCropHarvest(cropSlug) {
    setLoading({...loading, cropHarvest: true});

    const response = await fetchCrop();
    if (response === null) {
      return;
    }
    response.data = response.data.map(elem => {
      return {
        value: elem.slug,
        label: elem.name,
        ...elem
      }
    })
    setOptionsCropHarvest(response.data);
    setLoading({...loading, cropHarvest: false});
    if (cropSlug) {
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].value === cropSlug)
          return response.data[i];
      }
    }
  }

  async function fetchCropOffseason() {
    setLoading({...loading, cropOffSeason: true});
    const response = await fetchCrop()

    if (response === null) {
      return;
    }

    response.data = response.data.map(elem => {
      return {
        value: elem.id,
        label: elem.name,
        ...elem
      }
    });
    setOptionsCropOffseason([{slug: 0, name: ''}].concat(response.data))
    setLoading({...loading, cropOffSeason: false});
  }

  async function fetchClient() {
    setLoading({...loading, client: true});
    try {
      const response = await axios.get(`${host}/clients`);
      response.data = response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      })
      setOptionsClient(response.data)
      setLoading({...loading, client: false});
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter os clientes.' });
      setLoading({...loading, client: false});
      console.log(e.response);
    }
  }

  async function fetchField(clientId, farmId, _cropYear) {
    setLoading({...loading, field: true});
    try {
      const response = await axios.get(`${host}/clients/${clientId}/farms/${farmId}/areas?year=${_cropYear}`);
      response.data = response.data.map(elem => {
        const label = elem.block ? `${elem.block} | ${elem.field}` : elem.field;
        return {
          value: elem.id,
          label: label,
          ...elem
        }
      })
      setOptionsField(response.data);
      setLoading({...loading, field: false});
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as áreas da fazenda.' });
      setLoading({...loading, field: false});
      console.log(e.response);
    }
  }

  async function fetchAssemblyLine(pitFarmData) {
    if (pitFarmData.action === "pitFieldEdit") {
      setLoading({...loading, assemblyLine: true});
      try {
        const response = await axios.get(`${host}/pits-fields/${pitFarmData.assemblyLineId}`);
        setLoading({...loading, assemblyLine: false});
        return response.data
      }
      catch (e) {
        if (e.message === 'Request failed with status code 401') {
          window.location = process.env.REACT_APP_DASHBOARD_URL;
          return;
        }
        toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
        setLoading({...loading, assemblyLine: false});
        console.log(e.response);
      }
    }
    return null;
  }

  async function loadHarvestAndOffSeasonOnScreen(assemblyLine, pitFarmData) {
    if (pitFarmData.action === "pitFieldEdit") {
      if (assemblyLine === null) {
        return;
      }
      const workstationInitialInput = assemblyLine.workstations[0].input;
      let variety;
      if (workstationInitialInput) {
        const _harvest = workstationInitialInput.harvest;
        if (_harvest.plantingDate) {
          let strSplitDate = String(_harvest.plantingDate).split("-");
          let day = strSplitDate[2];
          let month = strSplitDate[1];
          let year = strSplitDate[0];
          setPlantingDateHarvest(`${day}/${month}/${year}`)
        }

        if (_harvest.crop !== "") {
          let cropHarvest = await fetchCropHarvest(_harvest.crop);
          setCropHarvest(cropHarvest);
        }

        if (_harvest.varietyId) {
          variety = await fetchVariety(_harvest.varietyId);
          setVarietyHarvest(variety);
        }

        setIrrigationHarvest(_harvest.irrigated)

        setTargetYieldHarvest(_harvest.targetYield);
        setTargetYieldExpectedHarvest(_harvest.targetYieldExpected);

      }
      if (workstationInitialInput) {
        if (workstationInitialInput.offSeason) {
          if (workstationInitialInput.offSeason.length > 0) {
            const _cropOffSeason = workstationInitialInput.offSeason[0]
            if (_cropOffSeason !== "")
              setCropOffSeason(_cropOffSeason.crop)
            if (_cropOffSeason.plantingDate) {
              let strSplitDate = String(_cropOffSeason.plantingDate).split("-");
              let day = strSplitDate[2];
              let month = strSplitDate[1];
              let year = strSplitDate[0];
              setPlantingDateOffSeason(`${day}/${month}/${year}`)
            }
            if (_cropOffSeason.crop !== "") {
              let crop = await fetchCropHarvest(_cropOffSeason.crop);
              setCropOffSeason(crop);
            }

            if (_cropOffSeason.varietyId) {
              variety = await fetchVariety(_cropOffSeason.varietyId);
              setVarietyOffSeason(variety);
            }

            setTargetYieldOffSeason(_cropOffSeason.targetYield);
            setTargetYieldExpectedOffSeason(_cropOffSeason.targetYieldExpected);
            setIrrigationOffSeason(_cropOffSeason.irrigated)
          }
        }
      }

    }

  }

  async function loadClient(assemblyLine, pitFarmData) {
    setLoading({...loading, client: true});
    try {
      let _response = await axios.get(`${host}/clients`);
      _response.data = _response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      })
      setOptionsClient(_response.data)
      let _clientId = 0;
      if (pitFarmData.action === "pitFieldEdit") {
        if (assemblyLine === null) {
          setLoading({...loading, client: false});
          return null;
        }
        const workstationInitialInput = assemblyLine.workstations[0].input;
        if (workstationInitialInput)
          _clientId = workstationInitialInput.clientId;
      }
      if (pitFarmData.action === "pitFarmAdd" || pitFarmData.action === "pitFarmEdit") {
        _clientId = pitFarmData.clientId;
      }
      let _client = null;
      if (_clientId > 0) {
        _client = _response.data.find(
          (_client) => {
            if (_client.id === parseInt(_clientId)) {
              setLoading({...loading, client: false});
              return _client
            }
          });
        setClient({id: _clientId, farmerId: _client.farmerId});
      }
      setLoading({...loading, client: false});
      return _client;
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter os clientes' });
      setLoading({...loading, client: false});
      console.log(e.response);
    }
  }

  async function loadFarm(assemblyLine, client, pitFarmData) {
    let farmId = 0;
    if (pitFarmData.action === "pitFieldEdit") {
      if (assemblyLine === null) {
        return;
      }
      const workstationInitialInput = assemblyLine.workstations[0].input;
      if (workstationInitialInput)
        farmId = workstationInitialInput.farmId;
    }
    if (pitFarmData.action === "pitFarmAdd" || pitFarmData.action === "pitFarmEdit") {
      farmId = pitFarmData.farmId;
    }
    if (client === null) {
      return;
    }

    if (client.farms === undefined) {
      setLoading({...loading, farm: true});
      try {
        let _response = await axios.get(`${host}/clients/${client.id}/farms`);
        client.farms = _response.data || []
      }
      catch (e) {
        if (e.message === 'Request failed with status code 401') {
          window.location = process.env.REACT_APP_DASHBOARD_URL;
          return;
        }
        toasts.danger({ title: 'Erro!', message: 'Erro ao obter as fazendas' });
        setLoading({...loading, farm: false});
        console.log(e.response);
      }
    }
    client.farms = client.farms.map(elem => {
      return {
        value: elem.id,
        label: elem.name,
        ...elem
      }
    });
    setOptionsFarm(client.farms);
    for (let i = 0; i < client.farms.length; i++) {
      if (client.farms[i].id === farmId)
        return client.farms[i];
    }
  }

  async function loadFields(assemblyLine, pitFarmData) {
    if (pitFarmData.action === "pitFieldAdd") {
      return;
    }
    let clientId = null;
    let farmId = null;
    let fieldId = null;
    let _cropYear = null;
    if (pitFarmData.action === "pitFieldEdit") {
      if (assemblyLine === null) {
        return;
      }
      const workstationInitialInput = assemblyLine.workstations[0].input;
      if (workstationInitialInput) {
        fieldId = workstationInitialInput.fieldId;
        clientId = workstationInitialInput.clientId;
        farmId = workstationInitialInput.farmId;
        _cropYear = workstationInitialInput.cropYear;
      }
    }
    if (pitFarmData.action === "pitFarmEdit" || pitFarmData.action === "pitFarmAdd") {
      fieldId = pitFarmData.fieldId;
      clientId = pitFarmData.clientId;
      farmId = pitFarmData.farmId;
      _cropYear = pitFarmData.cropYearArea;
    }
    if (!clientId || !farmId || !_cropYear) {
      return;
    }

    setLoading({...loading, field: true});
    try {
      const _response_field = await axios.get(`${host}/clients/${clientId}/farms/${farmId}/areas?year=${_cropYear}`);
      _response_field.data = _response_field.data.map(elem => {
        const label = elem.block ? `${elem.block} | ${elem.field}` : elem.field;
  
        return {
          value: elem.id,
          label: label,
          ...elem
        }
      })
  
      setOptionsField(_response_field.data);
      setLoading({...loading, field: false});
      for (let i = 0; i < _response_field.data.length; i++) {
        if (_response_field.data[i].id === fieldId)
          return _response_field.data[i];
      }
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as áreas da fazenda.' });
      setLoading({...loading, field: false});
      console.log(e.response);
    }
  }

  async function loadCropYear(assemblyLine, pitFarmData) {
    try {
      if (pitFarmData.action === "pitFieldEdit") {
        if (assemblyLine === null) {
          return;
        }
        const workstationInitialInput = assemblyLine.workstations[0].input;
        if (workstationInitialInput)
          setCropYear({value: workstationInitialInput.cropYear, label: workstationInitialInput.cropYear});
      }
      if (pitFarmData.action === "pitFarmAdd" || pitFarmData.action === "pitFarmEdit") {
        setCropYear({value: pitFarmData.cropYearArea, label: pitFarmData.cropYearArea});
      }
    } catch (e) {
      console.log('linha 408', e);
    }
  }

  async function loadScreen(pitFarmData) {
    try {
      if (pitFarmData.assemblyLineId) {
        assemblyLineId = pitFarmData.assemblyLineId;
      }

      if (pitFarmData.action === "pitFieldEdit") {
        blockFieldsToEdit(true);
      }

      let assemblyLine = await fetchAssemblyLine(pitFarmData);
      await loadHarvestAndOffSeasonOnScreen(assemblyLine, pitFarmData);
      await loadCropYear(assemblyLine, pitFarmData);

      let client = await loadClient(assemblyLine, pitFarmData);
      let farm = await loadFarm(assemblyLine, client, pitFarmData);
      let field = await loadFields(assemblyLine, pitFarmData);

      if (client) {
        setClient(client);
      }
      if (farm) {
        setFarm(farm);
      }
      if (field) {
        setField(field);
      }

    } catch (e) {
      console.log(e)
    }
  }

  function showMessage(title, message, type) {
    if (type === 'danger') {
      toasts.danger({title, message})
      return
    }
    if (type === 'success') {
      toasts.success({title, message: message})
      return
    }
    if (type === 'warning') {
      toasts.warning({title, message: message})
      return
    }
    toasts.info({title, message: message})

  }

  function format_date(_date) {
    if (_date === "" || _date === undefined)
      return "";
    const array = _date.split("/")
    return `${array[2]}-${array[1]}-${array[0]}`
  }

  function convertStringToInt(numberStr) {
    try {
      let numberInt = parseInt(numberStr);
      if (numberInt === NaN) {
        return 0;
      }
      return numberInt;
    } catch (e) {
      console.log("Erro ao converter numero de string para int")
      return 0;
    }
  }

  useEffect(async () => {
    fetchCropHarvest(null);
    fetchCropOffseason();
    fetchClient();
    fetchCropYear();
    fetchVariety(null);
    await loadScreen(pitFarmData)
  }, []);

  function blockFieldsToEdit(canBlock) {
    setBlockFields(
      {
        client: canBlock,
        farm: canBlock,
        cropYear: canBlock,
        field: canBlock
      })
  }

  function fillObjectToSend() {
    const harvestPlantingDate = format_date(plantingDateHarvest);
    const offseasonPlantingDate = format_date(plantingDateOffSeason);
    let listOffSeason = []
    const offSeason = {
      crop: cropOffSeason.id === 0 ? "" : cropOffSeason.slug,
      plantingDate: offseasonPlantingDate,
      targetYield: targetYieldOffSeason,
      targetYieldExpected: targetYieldExpectedOffSeason,
      varietyId: varietyOffSeason ? varietyOffSeason.id : 0,
      irrigated: irrigationOffSeason
    }

    if (!isOffSeasonEmpty([offSeason]))
      listOffSeason.push(offSeason);
    return {
      reportEmail: '',
      clientId: client.id === undefined || client.id === 0 ? "" : convertStringToInt(client.id),
      farmerId: client.farmerId === undefined ? convertStringToInt(client.farmer_id) : convertStringToInt(client.farmerId),
      farmId: farm.id === 0 ? "" : convertStringToInt(farm.id),
      fieldId: field.id === 0 ? "" : convertStringToInt(field.id),
      cropYear: cropYear.value,
      harvest: {
        crop: cropHarvest.slug,
        plantingDate: harvestPlantingDate,
        targetYield: targetYieldHarvest,
        targetYieldExpected: targetYieldExpectedHarvest,
        varietyId: varietyHarvest ? varietyHarvest.id : 0,
        irrigated: irrigationHarvest
      },
      offSeason: listOffSeason
    }
  }

  async function onSubmit(values, actions) {
    setIsLoading(true);
    try {
      let data = fillObjectToSend();

      if (has_empty_fields(data)) {
        setIsLoading(false);
        return;
      }

      if (assemblyLineId !== undefined) {
        try {
          await axios.put(`${host}/pits-fields/${assemblyLineId}`, data);
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao atualizar PIT Talhão' });
          setIsLoading(false);
          console.log(e.response);
          return;
        }
      }
      else {
        try {
          await axios.post(`${host}/pits-fields`, data);
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao criar PIT Talhão' });
          setIsLoading(false);
          console.log(e.response);
          return;
        }
      }
      showMessage('Sucesso', 'Cadastrado com sucesso', 'success');
      buttonBackChangeHandler();
    } catch (error) {
      console.log(error);
      let msg = error.response && error.response.data.message ? error.response.data.message : error.message;
      showMessage('Erro', msg, 'danger');
    }
    setIsLoading(false);
  }

  function isHarvestEmpty(formData) {
    if (formData.harvest.crop === "" &&
      formData.harvest.plantingDate === "" &&
      formData.harvest.targetYield === ""
    )
      return true
    return false;
  }

  function isRequiredFieldsHarvestEmpty(formData) {
    const _type = 'warning';
    const title = 'Campo vazio';
    let error = false;
    let data = formData.harvest;
    if (data.crop === "") {
      showMessage(title, 'Selecione um cultura da safra', _type);
      error = true
    }
    if (data.plantingDate === "") {
      showMessage(title, 'Insira a data da safra', _type);
      error = true
    }
    if (data.targetYield === "") {
      showMessage(title, 'Insira a produtividade da safra', _type);
      error = true
    }
    return error;
  }

  function isOffSeasonEmpty(offSeason) {
    if (offSeason.length === 0)
      return true;
    if ((offSeason[0].crop === "" || offSeason[0].crop.id === 0) &&
      offSeason[0].plantingDate === "" &&
      offSeason[0].targetYield === ""
    )
      return true
    return false;
  }

  function is_required_fields_of_offseason_empty(formData) {
    const _type = 'warning';
    const title = 'Campo vazio';
    let error = false;
    let data = formData.offSeason[0]
    if (data.crop === "") {
      showMessage(title, 'Selecione um cultura da safrinha', _type);
      error = true
    }
    if (data.plantingDate === "") {
      showMessage(title, 'Insira a data da safrinha', _type);
      error = true
    }
    if (data.targetYield === "") {
      showMessage(title, 'Insira a produtividade da safrinha', _type);
      error = true
    }
    return error;
  }

  function has_empty_fields(data_to_send) {
    let error = false;
    const _type = 'warning';
    const title = 'Campo vazio';
    if (data_to_send.farmerId === "" || data_to_send.farmerId === undefined) {
      showMessage(title, 'Selecione um agricultor', _type);
      error = true;
    }
    if (data_to_send.farmId === "" || data_to_send.farmId === undefined) {
      showMessage(title, 'Selecione uma fazenda', _type);
      error = true;
    }
    if (data_to_send.fieldId === "" || data_to_send.fieldId === undefined) {
      showMessage(title, 'Selecione uma talhão', _type);
      error = true;
    }
    if (data_to_send.cropYear === "") {
      showMessage(title, 'Selecione uma ano safra', _type);
      error = true;
    }
    if (isHarvestEmpty(data_to_send) && isOffSeasonEmpty(data_to_send.offSeason)) {
      showMessage(title, 'Entre com os dados da safra ou da safrinha', _type);
      return true;
    }

    if (!isHarvestEmpty(data_to_send)) {
      if (isRequiredFieldsHarvestEmpty(data_to_send))
        return true;
    }
    if (!isOffSeasonEmpty(data_to_send.offSeason)) {
      if (is_required_fields_of_offseason_empty(data_to_send))
        return true;
    }
    return error;
  }

  const validate = (values, props /* only available when using withFormik */) => {
    return {};
  };

  const clientChangeHandler = (event) => {
    const optionId = event.value;
    if (optionId === 0) {
      setOptionsFarm([{id: 0, name: "selecione uma fazenda"}]);
      setFarm({})
      return;
    }
    var _client = optionsClient.find((optionValue) => {
      if (optionValue.value === optionId) {
        return optionValue
      }
    });
    if (_client === undefined)
      return;
    setClient(event);
    setFarm({});
    setCropYear({});
    fetchClientFarms(event.value);
  }

  const farmChangeHandler = (event) => {
    let option = event;
    let optionId = option.id;
    if (optionId === "0" || optionId === undefined) {
      setOptionsFarm([{id: 0, name: "selecione uma fazenda"}]);
      setFarm({})
      return;
    }
    const farmId = parseInt(optionId);
    if (farmId) {
      var farm = optionsFarm.find((farm) => {
        if (farm.id === farmId) {
          return farm
        }
      });
      setFarm(event);
      setCropYear({});
    }

  }

  const cropYearChangeHandler = async (event) => {
    setCropYear(event);
    if (farm.id > 0) {
      await fetchField(farm.clientId, farm.id, event.label);
    } else {
      console.log("Erro: farm.id vazio");
    }
  }

  const fieldChangeHandler = (event) => {
    setField(event);
  }

  const harvestCropChangeHandler = (event) => {
    setCropHarvest(event);
  }
  const plantingDateHarvestChangeHandler = (event) => {
    setPlantingDateHarvest(event.target.value);
  }
  const targetYieldHarvestChangeHandler = (event) => {
    setTargetYieldHarvest(event.target.value);
  }
  const targetYieldExpectedHarvestChangeHandler = (event) => {
    setTargetYieldExpectedHarvest(event.target.value);
  }
  const varietyHarvestChangeHandler = (event) => {
    setVarietyHarvest(event);
  }
  const irrigationHarvestChangeHandler = (event) => {
    setIrrigationHarvest(event.target.checked);
  }

  const cropOffSeasonChangeHandler = (event) => {
    setCropOffSeason(event);
  }
  const plantingDateOffSeasonChangeHandler = (event) => {
    setPlantingDateOffSeason(event.target.value);
  }
  const targetYieldOffSeasonChangeHandler = (event) => {
    setTargetYieldOffSeason(event.target.value);
  }
  const targetYieldExpectedOffSeasonChangeHandler = (event) => {
    setTargetYieldExpectedOffSeason(event.target.value);
  }
  const varietyOffSeasonChangeHandler = (event) => {
    setVarietyOffSeason(event);
  }
  const irrigationOffSeasonChangeHandler = (event) => {
    setIrrigationOffSeason(event.target.checked);
  }

  const buttonBackChangeHandler = () => {

    if (pitFarmData.action === "pitFieldAdd" || pitFarmData.action === "pitFieldEdit") {
      history.push("/pit/field")
    }

    if (pitFarmData.action === "pitFarmAdd") {
      history.push(
        `/pit/farm/cad?action=pitFiledBack` +
        `&assemblyLinePitFarmId=${pitFarmData.assemblyLinePitFarmId}&clientId=${client.id}&farmId=${farm.id}&farmerId=${client.farmerId}`
      );
    }

    if (pitFarmData.action === "pitFarmEdit") {
      history.push(
        `/pit/farm/cad/${pitFarmData.assemblyLinePitFarmId}?action=pitFiledBack&clientId=${client.id}&farmId=${farm.id}&farmerId=${client.farmerId}`
      );
    }
  }

  return (
    <form>
      <div className={generalClasses['page-container']}>
        <Box alignX="center" className={generalClasses['top-buttons']}>
          <Flex>
            {!isLoading && <Button palette="success" type="button" onClick={onSubmit}>Salvar</Button>}
            {isLoading && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
            <Box width="30px"/>
            <Button palette="gray" onClick={() => history.push("/pit/field")}> Voltar</Button>
          </Flex>
        </Box>

        <Divider/>

        <div className={formClasses['form-group']}>
          <h4 className={formClasses['form-group-title']}>Dados do Talhão</h4>
          <Flex>
            <Box width="70px" height="50px">
              <label>Cliente:&nbsp;</label>
            </Box>
            <Box width="400px" height="50px">
              <Select placeholder="" value={client} options={optionsClient} onChange={clientChangeHandler}/>
            </Box>
            {loading.client && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="70px" height="50px">
              <label>Fazenda:&nbsp;</label>
            </Box>
            <Box width="400px" height="50px">
              <Select options={optionsFarm} value={farm} onChange={farmChangeHandler} placeholder=""/>
            </Box>
            {loading.farm && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="80px" height="50px">
              <label>Ano safra:&nbsp;</label>
            </Box>
            <Box width="390px" height="50px">
              <Select options={optionsCropYear} value={cropYear} onChange={cropYearChangeHandler} placeholder=""/>
            </Box>
            {loading.cropYear && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="70px" height="50px">
              <label>Talhão:&nbsp;</label>
            </Box>
            <Box width="400px" height="50px">
              <Select options={optionsField} value={field} onChange={fieldChangeHandler} placeholder=""/>
            </Box>
            {loading.field && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
        </div>

        <Divider/>

        <div className={`${formClasses['form-group']} ${formClasses['side-by-side']}`}>
          <h4 className={formClasses['form-group-title']}>Safra</h4>
          <Flex>
            <Box width="70px" height="50px">
              <label>Cultura:&nbsp;</label>
            </Box>
            <Box width="400px" height="50px">
              <Select options={optionsCropHarvest} value={cropHarvest} onChange={harvestCropChangeHandler} placeholder=""/>
            </Box>
            {loading.cropHarvest && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="130px" height="50px">
              <label>Data de plantio:&nbsp;</label>
            </Box>
            <Box width="70px" height="50px">
              <InputMask name="harvestPlantingDate" value={plantingDateHarvest} onChange={plantingDateHarvestChangeHandler} mask="99/99/9999"/>
            </Box>
          </Flex>
          <Flex>
            <Box width="120px" height="50px">
              <label>Produtividade:&nbsp;</label>
            </Box>
            <Box width="70px" height="50px">
              <input type="text" value={targetYieldHarvest} onChange={targetYieldHarvestChangeHandler}/>
            </Box>
          </Flex>
          <Flex>
            <Box width="190px" height="50px">
              <label>Produtividade esperada:&nbsp;</label>
            </Box>
            <Box width="70px" height="50px">
              <input type="text" value={targetYieldExpectedHarvest} onChange={targetYieldExpectedHarvestChangeHandler}/>
            </Box>
          </Flex>
          <Flex>
            <Box width="100px" height="50px">
              <label>Variedade:&nbsp;</label>
            </Box>
            <Box width="370px" height="50px">
              <Select options={optionsVariety} value={varietyHarvest} onChange={varietyHarvestChangeHandler} placeholder=""/>
            </Box>
              {loading.variety && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="70px" height="50px">
              <label> Irrigado: </label>
            </Box>
            <Box width="70px" height="50px">
              <input type="checkbox" checked={irrigationHarvest} onChange={irrigationHarvestChangeHandler} name="irrigationHarvest"/>
            </Box>
          </Flex>
        </div>

        <div className={`${formClasses['form-group']}  ${formClasses['side-by-side']}`}>
          <h4 className={formClasses['form-group-title']}>Safrinha</h4>
          <Flex>
            <Box width="70px" height="50px">
              <label>Cultura:&nbsp;</label>
            </Box>
            <Box width="400px" height="50px">
              <Select options={optionsCropOffSeason} value={cropOffSeason} onChange={cropOffSeasonChangeHandler} placeholder=""/>
            </Box>
            {loading.cropOffSeason && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="130px" height="50px">
              <label>Data de plantio:&nbsp;</label>
            </Box>
            <Box width="70px" height="50px">
              <InputMask name="harvestPlantingDate" value={plantingDateOffSeason} onChange={plantingDateOffSeasonChangeHandler} mask="99/99/9999"/>
            </Box>
          </Flex>
          <Flex>
            <Box width="120px" height="50px">
              <label>Produtividade:&nbsp;</label>
            </Box>
            <Box width="120px" height="50px">
              <input type="text" name="offseasonTargetYield" value={targetYieldOffSeason} onChange={targetYieldOffSeasonChangeHandler}/>
            </Box>
          </Flex>
          <Flex>
            <Box width="190px" height="50px">
              <label>Produtividade esperada:&nbsp;</label>
            </Box>
            <Box width="70px" height="50px">
              <input type="text" name="offseasonTargetYieldExpected" value={targetYieldExpectedOffSeason} onChange={targetYieldExpectedOffSeasonChangeHandler}/>
            </Box>
          </Flex>
          <Flex>
            <Box width="100px" height="50px">
              <label>Variedade:&nbsp;</label>
            </Box>
            <Box width="370px" height="50px">
              <Select options={optionsVariety} value={varietyOffSeason} onChange={varietyOffSeasonChangeHandler} placeholder=""/>
            </Box>
            {loading.variety && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
          </Flex>
          <Flex>
            <Box width="70px" height="50px">
              <label>Irrigado:</label>
            </Box>
            <Box width="70px" height="50px">
              <input type="checkbox" checked={irrigationOffSeason} onChange={irrigationOffSeasonChangeHandler} name="irrigationHarvest"/>
            </Box>
          </Flex>
        </div>
      </div>
    </form>
  );
};

export default PitFieldForm;