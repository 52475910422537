import React from 'react';
import {useHistory} from "react-router-dom";
import { Button, Divider} from 'bumbag';
import axios from "axios";

import generalClasses from '../css/General.module.css';
import homeClasses from '../css/Home.module.css';

const Home = () => {
    let history = useHistory();

    const host = process.env.REACT_APP_EASY_FARM_API_URL;

    axios.get(`${host}/me`).then(
      () => {},
      () => {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
      }
    );

    return (
        <div className={generalClasses['page-container-no-buttons']}>
            <Divider />
            <div className={homeClasses['button-container']}>
              <Button onClick={() => history.push("/pit/field")}>PIT Talhão</Button>
              <Button onClick={() => history.push("/pit/farm")}>PIT Fazenda</Button>
            </div>
        </div>
    );
};

export default Home;
