import React, { useState , useEffect} from 'react';
import { Formik, Field} from 'formik';
import {Button, Flex, Spinner} from 'bumbag'
import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import { useToasts , Box, Divider} from 'bumbag'

const PitFieldForm = (props) => {
    const [client, setClient] = useState({id:0});
    const [farm, setFarm] = useState({id: 0});
    const [field, setField] = useState({id: 0});
    const [cropYear, setCropYear] = useState({id: 0});
    const [cycleId, setCycleId] = useState(0);

    const [product, setProduct] = useState({id: 0});

    const [interpolation, setInterpolation] = useState({nome: ""});

    const [interpolationImage, setInterpolationImage] = useState({});

    const [optionsCropYear, setOptionsCropYear] = useState([]);
    const [optionsClient, setOptionsClient] = useState([]);
    const [optionsFarm, setOptionsFarm] = useState([]);
    const [optionsField, setOptionsField] = useState([]);
    const [optionsInterpolation, setOptionsInterpolation] = useState([]);
    const [optionsProduct, setOptionsProduct] = useState([]);

    const host = process.env.REACT_APP_EASY_FARM_API_URL;
    const token = localStorage.getItem('token');
    let history = useHistory();
    const UrlData = null;
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    };

    async function fetchCropYear() {
        const response = await axios.get(`${host}/harvest-years`, {headers});
        if (response.status !== 200){
           console.log('Erro load crop year')
           return;
        }
        setOptionsCropYear([''].concat(response.data));
    }

    async function fetchClient(){
         const response =  await axios.get(`${host}/clients`, {headers});
         setOptionsClient([{id:0, name:'selecione um cliente'} ].concat(response.data))
    }

    async function fetchField(farmId, _cropYear){
        const response =  await axios.get(`${host}/farms/${farmId}/areas?year=${_cropYear}`, {headers});
        if (response.status !== 200){
           console.log('Erro load crop year')
           return;
        }
        setOptionsField([{id: 0, name:''}].concat(response.data));
    }

    async function loadScreen(UrlData){
        fetchClient();
        fetchCropYear();
    }

    useEffect( async ()=>{
        await loadScreen(UrlData)
    },[]);

    const clientChangeHandler = (event) => {
        const optionId = event.target.value;
        if(optionId === 0 ){
            setOptionsFarm([{id:0, name:"selecione uma fazenda"}]);
            setFarm({})
            return;
        }
        var _client = optionsClient.find((optionValue) => { if (optionValue.id === optionId){return optionValue}} );
        if(_client === undefined)
            return;
        setClient(_client);
        setOptionsFarm([{id:0, name:"selecione uma fazenda"}].concat(_client.farms));
    }
    const farmChangeHandler = (event) => {
        let optionId = event.target.value;
        if(optionId === "0" || optionId === undefined ){
            setOptionsFarm([{id:0, name:"selecione uma fazenda"}]);
            setFarm({})
            return;
        }
        console.log(event.target.value)
        const farmId = parseInt(event.target.selectedOptions[0].value);
        if(farmId){
           var farm = optionsFarm.find((farm)=> { if (farm.id === farmId){return farm}} );
           setFarm(farm);
           console.log('farm', farm)
        }
    }
    const cropYearChangeHandler = async (event) => {
        setCropYear(event.target.value);
        await fetchField(farm.id, event.target.value);
    }
    const fieldChangeHandler = async (event) => {
        let areaId = event.target.value;
        if(areaId === "0") {
            setField({id:0});
            setOptionsProduct([]);
            setOptionsInterpolation([]);
            setCycleId(0);
            setInterpolation({});
            return;
        }

        setField({id:event.target.value});
        let clientId = client.sm_id;
        let farmId = farm.sm_id;

        const response =  await axios.get(
            `${host}/cycles/process/satimages?clientId=${clientId}&farmId=${farmId}&areaId=${areaId}&cropYear=${cropYear}`, {headers});
        if (response.status !== 200){
            throw Error('Erro on request')
        }

        setOptionsInterpolation([{id: 0, name:''}].concat(response.data.interpolations));
        setCycleId(response.data.cycleId);
    }

    async function getImage(clientId, farmId, areaId, productId, _cycleId, interpolationId){
        let url = `${host}/cycles/process/satimages/${interpolationId}/product/${productId}?clientId=${clientId}&farmId=${farmId}&cycleId=${cycleId}`;
        const response = await axios.get(url, {headers});
        setInterpolationImage( response.data);
    }

    const interpolationChangeHandler = async (event) => {
        const _interpolationLabel = event.target.value;
        const _interpolation = optionsInterpolation.find((item)=>{
            if(item.nome === _interpolationLabel)
                return true;
            return false;
        });
        if(_interpolation){
            setOptionsProduct([''].concat(_interpolation.listIndex));
            setInterpolation(_interpolation);
            return;
         }
        console.log("Não foi possível encontrar a interpolação")
    }

    const productChangeHandler = async (event) =>{
        const _productLabel = event.target.value;
        const clientId = client.sm_id;
        const farmId = farm.sm_id;
        const areaId = field.id;

        const _product = interpolation.products.find((item)=>{
            if(item.descricao === _productLabel)
                return true;
            return false;
        });
        if(_product){
            await getImage(clientId, farmId, areaId, _product.id, cycleId, interpolation.id);
            setProduct(_product);
            return;
        }
        console.log("Não foi possível encontrar o produto da interpolação")
    }
    const buttonBackChangeHandler = () => {
        history.push("/")
    }

    return (
        <Formik
            initialValues={{checked: []}}
            render={ ({values,   touched, handleSubmit, errors,   handleChange,
                handleBlur,}) => (
                <form onSubmit={handleSubmit} >
                    <h3>Dados de coleita e plantio</h3>
                    <div>
                        <label>Cliente:&nbsp;</label>
                        <Field
                            value={client.id}
                            onChange={clientChangeHandler}
                            name="optionsClient"
                            as="select"
                        >
                            {
                                optionsClient.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Fazenda:&nbsp;</label>
                        <Field name="optionsFarm" as="select"
                               value={farm.id}
                               onChange={farmChangeHandler}
                        >
                            {
                              optionsFarm &&   optionsFarm.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Ano safra:&nbsp;</label>
                        <Field name="cropYear" as="select"
                               value={cropYear}
                               onChange={cropYearChangeHandler}
                        >
                            {
                                optionsCropYear.map(ele => {
                                    return (<option value={ele}>{ele}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Talhão:&nbsp;</label>
                        <Field name="optionsField" as="select"
                               value={field.id}
                               onChange={fieldChangeHandler}
                        >
                            {
                                optionsField.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>

                    <div>
                        <label>Imagem:&nbsp;</label>
                        <Field name="optionsInterpolation" as="select"
                               value={setInterpolation.nome}
                               onChange={interpolationChangeHandler}
                        >
                            {
                                optionsInterpolation.map(ele => {
                                    return (<option value={ele.nome}>{ele.nome}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Índice de vegetação:&nbsp;</label>
                        <Field name="optionsProduct" as="select"
                               value={product.descricao}
                               onChange={productChangeHandler}
                        >
                            {
                                optionsProduct.map(ele => {
                                    return (<option value={ele}>{ele}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <br/>
                    <Divider></Divider>
                    { interpolationImage &&  <img src={interpolationImage.raster}/>}
                    <Divider></Divider>
                     <Box  height="10px"></Box>
                    <Flex>
                        <Box width="100px"></Box>
                        <Button  palette="danger" onClick={() => buttonBackChangeHandler() }> Voltar</Button>
                    </Flex>
                </form>
                )
            }
        ></Formik>
    );
};

export default PitFieldForm;