import {Button, Divider, Flex, Box, Spinner, useToasts} from "bumbag";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";

import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import generalClasses from '../css/General.module.css';


const PitFarmResult = () => {
  let history = useHistory();
  const [assemblyLineResult, setAssemblyLineResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const {assemblyLineId} = useParams();
  const token = localStorage.getItem('token');
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  const toasts = useToasts();

  async function loadScreen(assemblyLineId) {
    await loadResult(assemblyLineId)
  }

  useEffect(async () => {
    await loadScreen(assemblyLineId);
  }, []);

  async function loadResult(assemblyLineId) {
    try {
      const response = await axios.get(` ${host}/pits-farms/${assemblyLineId}?action=result`);
      setLoading(false);
      setAssemblyLineResult(response.data);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não foi possível carregar resultado!' });
      setLoading(false);
      console.log(e.response);
    }
  }

  const showRows = () => {
    const tables = [];
    let field;
    for (let i = 0; i < assemblyLineResult.length; i++) {
      field = assemblyLineResult[i];
      tables.push(
        (
          <Tr key={i} className={generalClasses['tr-background']}>
            <Td>{field.farmLabel}</Td>
            <Td>{field.fieldLabel}</Td>
            <Td className={generalClasses['td-number']}>{field.fieldArea.toLocaleString('pt-BR', {maximumFractionDigits: 2})}</Td>
            <Td className={generalClasses['td-number']}>{field.cv.toLocaleString('pt-BR', {maximumFractionDigits: 16})}</Td>
            <Td className={generalClasses['td-center']}>{field.targetYieldLabel}</Td>
            <Td className={generalClasses['td-center']}>{field.sampleStrategyType}</Td>
            <Td className={generalClasses['td-center']}>{field.strategyNutritional}</Td>
            <Td className={generalClasses['td-number']}>{field.densitySample.toLocaleString('pt-BR', {maximumFractionDigits: 2})}</Td>
            <Td>{field.typeAnalyze}</Td>
          </Tr>
        )
      );
    }
    return tables;
  }

  return (
    <div className={generalClasses['page-container']}>
      <Box alignX="center" className={generalClasses['top-buttons']}>
        <Flex>
          <Button palette="gray" onClick={() => history.push("/pit/farm")}> Voltar</Button>
        </Flex>
      </Box>
      <Divider/>
      {loading && <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
      {!loading &&
        <Table>
          <Thead>
            <Tr>
              <Th style={{width: '11%'}}>Fazenda</Th>
              <Th style={{width: '11%'}}>Talhão</Th>
              <Th style={{width: '6%'}}>Área</Th>
              <Th style={{width: '10%'}}>Índice InCeres</Th>
              <Th style={{width: '6%'}}>Classificação Produtividade</Th>
              <Th style={{width: '10%'}}>Tipo de Amostragem</Th>
              <Th style={{width: '10%'}}>Estratégia Nutricional</Th>
              <Th style={{width: '6%'}}>Desidade ha/amostra</Th>
              <Th style={{width: '30%'}}>Perfil de Análise</Th>
            </Tr>
          </Thead>
          <Tbody>
            {showRows()}
          </Tbody>
        </Table>
      }
    </div>
  );
};

export default PitFarmResult;

