import React, {useRef, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import * as ol from "ol";
import {WKT} from "ol/format";
import {Fill, Stroke, Style} from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OSM from 'ol/source/OSM';
import TileLayer from "ol/layer/Tile";
import {ImageStatic} from "ol/source";
import ImageLayer from "ol/layer/Image";

import {faArrowsAlt} from '@fortawesome/free-solid-svg-icons'

import MapContext from './MapContext';

import 'ol/ol.css';
import mapClasses from '../../css/Map.module.css';
import {Icon} from "bumbag";

const Map = forwardRef(({...props}, ref) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [areasOpacity, setAreasOpacity] = useState(100);
  const [sceneOpacity, setSceneOpacity] = useState(90);
  const [hasScene, setHasScene] = useState(false);

  function renderImage(satelliteScene) {
    map.getLayers().forEach((layer) => {
      if (layer instanceof ImageLayer) {
        map.removeLayer(layer);
      }
    });
    setSceneOpacity(90);
    setHasScene(false);

    if (satelliteScene === null) {
      return;
    }

    const sourceImage = new ImageStatic({
      url: satelliteScene.url,
      imageExtent: satelliteScene.bbox,
      projection: 'EPSG:4326',
    });

    const imageLayer = new ImageLayer({
      opacity: 0.9,
      source: sourceImage
    });
    map.getLayers().insertAt(2, imageLayer);
    setHasScene(true);
  }

  useImperativeHandle(ref, () => {
    return {
      renderImage
    }
  });

  function buildMap() {
    const styles = [
      new Style({
        stroke: new Stroke({
          color: '#f79e04',
          width: 2
        }),
        fill: new Fill({
          color: '#F79E04BF'
        })
      })
    ];
    const openStreetLayer = new TileLayer({source: new OSM()})

    const areasLayer = new VectorLayer({
      style: styles,
      opacity: 1,
      source: new VectorSource()
    });

    let options = {
      view: new ol.View({
        zoom: 0,
        center: [0, 0],
        constrainResolution: true
      }),
      layers: [
        openStreetLayer,
        areasLayer
      ],
      controls: [],
      overlays: [],
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    const feature = new ol.Feature();
    const wktFormat = new WKT();
    const polygon = wktFormat.readGeometry(props.boundary);
    feature.setGeometry(polygon.transform('EPSG:4326', 'EPSG:3857'));
    areasLayer.getSource().addFeature(feature);
    const extent = areasLayer.getSource().getExtent();
    mapObject.getView().fit(extent, mapObject.getSize());
    setMap(mapObject);
  }

  useEffect(() => {
    buildMap();
  }, []);

  function getAreasLayer() {
    let areasLayer = null;
    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        areasLayer = layer;
        return false;
      }
    });
    return areasLayer;
  }

  function getSceneLayer() {
    let sceneLayer = null;
    map.getLayers().forEach((layer) => {
      if (layer instanceof ImageLayer) {
        sceneLayer = layer;
        return false;
      }
    });
    return sceneLayer;
  }

  function centerMap() {
    const extent = getAreasLayer().getSource().getExtent();
    map.getView().fit(extent, map.getSize());
  }

  function settingAreasOpacity(event) {
    setAreasOpacity(event.target.value)
    getAreasLayer().setOpacity(areasOpacity / 100);
  }

  function settingSceneOpacity(event) {
    setSceneOpacity(event.target.value)
    getSceneLayer().setOpacity(sceneOpacity / 100);
  }

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className={mapClasses['ol-map']}>
      </div>
      <div className="ol-center-map ol-unselectable ol-control">
        <button type="button"  onClick={() => centerMap()}>
          <Icon aria-label="Centralizar" icon={faArrowsAlt} type="font-awesome" />
        </button>
      </div>
      <div className={`ol-unselectable ol-control ${mapClasses['slide-field-opacity']}`}>
        <input type="range" min={0} max={100} step={1} value={areasOpacity} onChange={settingAreasOpacity}/>
      </div>
      {
        hasScene &&
        <div className={`ol-unselectable ol-control ${mapClasses['slide-scene-opacity']}`}>
          <input type="range" min={0} max={100} step={1} value={sceneOpacity} onChange={settingSceneOpacity}/>
        </div>
      }
    </MapContext.Provider>
  );
});

export default Map;
