import { Button, Divider,Flex, Box, useToasts } from "bumbag";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => {
    let history = useHistory();
    const [email, setEmail] = useState([]);
    const toasts = useToasts();
    const token = localStorage.getItem('token');
    const host = process.env.REACT_APP_EASY_FARM_API_URL;

    function showMessage(title, message, type){
        if(type === 'danger'){
            toasts.danger({ title, message })
            return
        }
         if(type === 'success'){
            toasts.success({ title, message: message })
            return
         }
         if(type === 'warning'){
            toasts.warning({ title, message: message })
            return
         }
         toasts.info({ title, message: message })

    }

    const handleSubmit = async (e) => {
        try{
            e.preventDefault();
            const object_to_send = { email }
            const response =  await axios.post(`${host}/users/forgot-password`, object_to_send, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  },
            });
            if (response.status !== 200)
                 showMessage('Informaçõa',  'Não foi possível enviar e-mail', 'warning');

            if (response.data.code !== 200)
                 showMessage('Informaçõa',  response.data.message, 'warning');

            showMessage('Sucesso',  response.data.message, 'success');
        }catch (e) {
            console.log(e);
            showMessage('Informaçõa',  'Não foi possível enviar e-mail', 'warning');
        }
    }
    return (
        <div>
            <form onSubmit={e => { handleSubmit(e) }}>
                <Box alignX="center">
                    <Flex>
                        <Box width="30px"></Box>
                       <Button  palette="gray" onClick={() => history.push("/")}> Voltar</Button>
                    </Flex>
                    <Box height="10px"></Box>
                </Box>
                <Divider/>
                <Box alignX="center">
                    <br></br>
                    <label>Recuperação de senha</label>
                    <br></br>
                    <div>
                    <label>Email:&nbsp;</label>
                    <input value={email} onChange={e => setEmail(e.target.value)} name="email"></input>
                    </div>
                    <br></br>
                    <Button type='submit' palette="success" >Enviar</Button>
                </Box>
          </form>
        </div>
  );
};

export default ForgotPassword;

