import {Link, Button, Card, Divider, Label, Flex, Box, DropdownMenu, useToasts, Tag, Set, Callout , Stack} from "bumbag";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
    let history = useHistory();
    const [repeatPassword, setRepeatPassword] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const toasts = useToasts();
    const host = process.env.REACT_APP_EASY_FARM_API_URL;

    const queryParams = new URLSearchParams(window.location.search);
    const tokenResetPassword = queryParams.get('token');

    // if (queryParams.get('action') === "pitFarmEdit" || queryParams.get('action') === "pitFarmAdd"){


    function showMessage(title, message, type){
        if(type === 'danger'){
            toasts.danger({ title, message })
            return
        }
         if(type === 'success'){
            toasts.success({ title, message: message })
            return
         }
         if(type === 'warning'){
            toasts.warning({ title, message: message })
            return
         }
         toasts.info({ title, message: message })
    }

    const handleSubmit = async (e) => {
        try{
            e.preventDefault();
            if (password !== repeatPassword){
                showMessage('Alerta',  'Senha e repetir senha estão diferentes', 'warning');
                return;
            }
            if (!tokenResetPassword){
                showMessage('Erro',  'Token inválido', 'danger');
                return;
            }
            if (!password){
                showMessage('Alerta',  'Campo senha não pode ser vazio', 'warning');
                return;
            }
            if (!repeatPassword){
                showMessage('Alerta',  'Campo senha não pode ser vazio', 'warning');
                return;
            }
            if (!email){
                showMessage('Alerta',  'Campo e-mail não pode ser vazio', 'warning');
                return;
            }

            const object_to_send = { token:tokenResetPassword, email, password }
            const response =  await axios.post(`${host}/users/reset-password`, object_to_send, {
                headers: {
                    'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  },
            });

            if (response.status !== 200){
                showMessage('Alerta',  'Não foi possível alterar a senha', 'warning');
                return;
            }

            if (response.data.code !== 200){
                const message = response.data.message ? response.data.message : 'Não foi possível alterar senha';
                showMessage('Alerta',  message, 'warning');
                return;
            }

            showMessage('Sucesso',  response.data.message, 'success');
            history.push('/login');
        }catch (e) {
            console.log(e);
            showMessage('Exceção',  `${e}`, 'danger');
        }
    }
    return (
        <div>
            <form onSubmit={e => { handleSubmit(e) }}>
                <Box alignX="center">
                    <Flex>
                        <Box width="30px"></Box>
                       <Button  palette="gray" onClick={() => history.push("/")}> Voltar</Button>
                    </Flex>
                    <Box height="10px"></Box>
                </Box>
                <Divider/>
                <Box alignX="center">
                    <br></br>
                    <label>Recuperação de senha</label>
                    <br></br>
                       <div>
                        <label>E-mail:&nbsp;</label>
                        <input value={email} onChange={e => setEmail(e.target.value)} ></input>
                    </div>
                    <div>
                        <label>Senha:&nbsp;</label>
                        <input value={password} onChange={e => setPassword(e.target.value)} ></input>
                    </div>
                    <div>
                        <label>Repetir senha:&nbsp;</label>
                        <input value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} ></input>
                    </div>
                    <br></br>
                    <Button type='submit' palette="success" >Salvar</Button>
                </Box>
          </form>
        </div>
  );
};

export default ResetPassword;