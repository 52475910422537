import {Button, Divider, Flex, Box, Card, Spinner, useToasts, Icon} from "bumbag";
import React, {useEffect, useState, useRef, useReducer, forceUpdate} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {JSONEditor, JSONViewer} from "react-json-editor-viewer";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';

import Map from '../components/Map/Map'

import generalClasses from '../css/General.module.css';
import pitListClasses from '../css/PitList.module.css';

const PitFieldEtapas = () => {
  let history = useHistory();
  const [listaWorkstation, setListaWorkstation] = useState([]);
  const token = localStorage.getItem('token');
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  const toasts = useToasts();
  let {assemblyLineId} = useParams();
  const [loading, setLoading] = useState({
    workstations: true,
  });
  const mapRef = useRef([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  async function carregaEtapas() {
    let elem, i, workstation;
    if (listaWorkstation.length === 0) {
      const assemblyline = await fetchAssemblyLine(assemblyLineId, true);
      for (i = 0; i < assemblyline.workstations.length; i++) {
        elem = assemblyline.workstations[i];
        workstation = await fetchWorkstation(assemblyLineId, assemblyline.blueprintId, elem.id);
        workstation.showStartResult = false;
        workstation.assemblyLineId = assemblyline.id;
        workstation.showEndResult = false;
        assemblyline.workstations[i] = workstation;
      }
      setListaWorkstation(assemblyline.workstations);
      setLoading({workstations: false})
    }
  }

  useEffect(async () => {
    await carregaEtapas();
  }, []);

  async function refreshWorkstation() {
    setLoading({workstations: true});
    let elem, i, workstation;
    const assemblyline = await fetchAssemblyLine(assemblyLineId, true);
    for (i = 0; i < assemblyline.workstations.length; i++) {
      elem = assemblyline.workstations[i];
      workstation = await fetchWorkstation(assemblyLineId, assemblyline.blueprintId, elem.id);
      workstation.showStartResult = false;
      workstation.assemblyLineId = assemblyline.id;
      workstation.showEndResult = false;
      assemblyline.workstations[i] = workstation;
    }
    setListaWorkstation(assemblyline.workstations);
    setLoading({workstations: false})
  }

  async function fetchAssemblyLine(assemblyLineId, workstationResumida) {
    if (assemblyLineId) {
      try {
        const response = await axios.get(`${host}/pits-fields/${assemblyLineId}?workstationResumida=${workstationResumida}`);
        return response.data
      }
      catch (e) {
        if (e.message === 'Request failed with status code 401') {
          window.location = process.env.REACT_APP_DASHBOARD_URL;
          return;
        }
        toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
        console.log(e.response);
      }
    }
    return null;
  }

  async function fetchWorkstation(assemblyLineId, blueprintId, workstationId) {
    try {
      const response = await axios.get(`${host}/pits-fields/${assemblyLineId}/steps/${workstationId}`);
      return response.data
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter a etapa do PIT' });
      console.log(e.response);
    }
  }

  async function fixCloudDetection(_workstation) {
    try {
      await axios.put(
        `${host}/pits-fields/${_workstation.assemblyLineId}`,
        {fixCloudDetection: true}
      );
      toasts.success({ title: 'Corrigir', message: 'Enviado com sucesso!' });
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não consegui corrigir!' });
      console.log(e.response);
    }
  }

  async function reprocessar(_workstation) {
    const workstation = cloneDeep(_workstation);
    try {
      delete workstation.input.scene.visualizing;
    }
    catch (e) {}

    try{
      await axios.put(
        `${host}/pits-fields/${workstation.assemblyLineId}`,
        {workstation: {id: workstation.id, input: workstation.input}, reprocess: true}
      );
      toasts.success({ title: 'Reprocessar', message: 'Enviado com sucesso!' });
      refreshWorkstation();
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Não consegui reprocessar!' });
      console.log(e.response);
    }
  }

  function onJsonChange(_workstation, data) {
    _workstation.input = data;
  }

  function showJson(_workstation, property) {
    if (property === 'input') {
      return (
        <div className={generalClasses['json-editor']}>
          <JSONEditor data={_workstation.input} collapsible view="dual" onChange={(key, value, parent, data) => onJsonChange(_workstation, data)} />
        </div>
      );
    }
    return (
      <div className={generalClasses['json-editor']}>
        <JSONViewer data={_workstation.output} collapsible />
      </div>
    );
  }

  function alertaStatus(id, tipo) {
    let lista = listaWorkstation.map(elem => {
      if (elem.id === id) {
        if (tipo === 'entrada') {
          elem.showStartResult = !elem.showStartResult;
        }
        else {
          elem.showEndResult = !elem.showEndResult;
        }
      }
      return elem;
    })
    setListaWorkstation(lista);
  }

  function setSceneAsSelected(evaluatedScene, indexVegetation) {
    let lista = listaWorkstation.map(elem => {
      if (elem.name === 'PITIndexVegetationCalculate') {
        elem.input.scene = evaluatedScene;
        elem.input.scene.indexVegetation = indexVegetation;
        elem.showStartResult = true;
      }
      return elem;
    })
    setListaWorkstation(lista);
  }

  function showSceneInMap(workstation, evaluatedScene) {
    if (evaluatedScene.visualizing) {
      evaluatedScene.visualizing = false;
      setListaWorkstation(listaWorkstation);
      forceUpdate();

      mapRef.current.renderImage(null)
      return;
    }
    workstation.input.evaluatedScenes.forEach((_evaluatedScene) => {
      _evaluatedScene.visualizing = false;
    });
    evaluatedScene.visualizing = true;
    setListaWorkstation(listaWorkstation);
    forceUpdate();

    mapRef.current.renderImage(evaluatedScene)
  }

  function buildCanProduceButtons(evaluatedScene) {
    return evaluatedScene.canProduce.map(canProduce => {
      if (canProduce === 'RGB') {
        return false;
      }
      return <Button className={pitListClasses['button-use-this-scene']} key={`${evaluatedScene.sceneId}-${canProduce}`} onClick={() => setSceneAsSelected(evaluatedScene, canProduce)}>Usar {canProduce}</Button>
    });
  }

  function buildSatellitesButtons(workstation) {
    return workstation.input.evaluatedScenes.map(evaluatedScene => (
      <Box key={evaluatedScene.sceneId} className={`${pitListClasses['select-scene-item']} ${evaluatedScene.visualizing ? pitListClasses['selected'] : ''}`}>
        <Box className={pitListClasses['select-scene-item-date']}>
          {evaluatedScene.slug} - {evaluatedScene.date} - {evaluatedScene.sceneId}
        </Box>
        <Button palette="warning" onClick={() => showSceneInMap(workstation, evaluatedScene)} className={pitListClasses['select-scene-item-show']}>
          <Icon aria-label="Visualizar" icon={evaluatedScene.visualizing ? faEyeSlash : faEye} type="font-awesome" />
        </Button>
        <Box className={pitListClasses['can-produce-buttons-container']}>
          {buildCanProduceButtons(evaluatedScene)}
        </Box>
      </Box>
    ));
  }

  function startSelectScene(workstation) {
    workstation.selectingScene =! workstation.selectingScene;
    setListaWorkstation(listaWorkstation);
    forceUpdate();
  }

  const cardsArray = listaWorkstation.map(_workstation => (
    <Card standalone className={pitListClasses['list-pit-item']} key={_workstation.id}>
      <Card.Header className={`${pitListClasses['list-pit-item-header']} ${pitListClasses[_workstation.statusCode.toLowerCase()]}`}>
        <Card.Title>
          Etapa: {_workstation.id} -
          <span className={pitListClasses['list-pit-item-header-names']}>
            {_workstation.name}
          </span> => {_workstation.status}
        </Card.Title>
      </Card.Header>
      <Card.Content className={`${pitListClasses['list-pit-item-content']} ${_workstation.selectingScene ? pitListClasses['with-map'] : ''}`}>
        <Box className={pitListClasses['step-data-box']}>
          <Button className={pitListClasses['button-steps']} palette="success" onClick={() => alertaStatus(_workstation.id, 'entrada')}>Entrada</Button>
          {_workstation.showStartResult && <Button className={pitListClasses['button-steps']} palette="warning" onClick={() => reprocessar(_workstation)}>Reprocessar</Button>}
          {_workstation.name === 'PITCloudDetection' && <Button className={pitListClasses['button-steps']} palette="warning" onClick={() => fixCloudDetection(_workstation)}>Corrigir</Button>}
          {_workstation.name === 'PITIndexVegetationSelect' && _workstation.input.polygonWkt &&
            <Button className={pitListClasses['button-steps']} palette="warning" onClick={() => startSelectScene(_workstation)}>Cena</Button>
          }
          {_workstation.showStartResult && showJson(_workstation, 'input')}
        </Box>
        <Box className={pitListClasses['step-data-box']}>
          <Button className={pitListClasses['button-steps']} palette="success" variant="outlined" onClick={() => alertaStatus(_workstation.id, 'saida')}>Saída</Button>
          {_workstation.showEndResult && showJson(_workstation, 'output')}
        </Box>

        {_workstation.selectingScene &&
          <Box className={pitListClasses['select-scene-container']}>
            <Box className={pitListClasses['select-scene-header']}>
              Selecionar uma nova cena
              <Button className={pitListClasses['button-close-select-scene']} palette="danger" onClick={() => startSelectScene(_workstation)}>x</Button>
            </Box>
            <Box className={pitListClasses['map-container']}>
              <Map ref={mapRef} boundary={_workstation.input.polygonWkt}></Map>
            </Box>
            <Box className={pitListClasses['satellite-buttons-container']}>
              {buildSatellitesButtons(_workstation)}
            </Box>
          </Box>
        }
      </Card.Content>
    </Card>
  ));

  return (
    <div className={generalClasses['page-container']}>
      <Box alignX="center" className={generalClasses['top-buttons']}>
        <Flex>
          <Button palette="gray" onClick={() => history.push("/pit/field")}>Voltar</Button>
          <Button palette="warning" onClick={() => refreshWorkstation()}>Atualizar</Button>
        </Flex>
      </Box>
      <Divider/>
      {loading.workstations && <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
      {!loading.workstations && cardsArray}
    </div>
  );
};

export default PitFieldEtapas;

