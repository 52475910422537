import React, { useState, useEffect, useReducer } from 'react';
import {Button, Flex, Spinner, Popover, Icon, Switch, Table, Divider, Tabs} from 'bumbag'
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";
import { useToasts, Box } from 'bumbag'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { AsyncStorage } from "AsyncStorage";
import Collapsible from 'react-collapsible';

import generalClasses from '../../css/General.module.css';
import formClasses from '../../css/PitForm.module.css';

const PitFarmForm = (props) => {

  const [assemblyLine, setAssemblyLine] = useState({ id: 0 });
  const [client, setClient] = useState({});
  const [farm, setFarm] = useState({});

  const [optionsClient, setOptionsClient] = useState([{ id: 0, label: 'selecione um cliente' }]);
  const [optionsFarm, setOptionsFarm] = useState([{ id: 0, label: '' }]);
  const [listField, setListField] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [loading, setLoading] = useState({
    client: false,
    area: false,
    farm: false,
    assemblyLine: false,
    submit: false
  });
  const [totalAreaSelected, setTotalAreaSelected] = useState(0);
  const [unityAreaSelected, setUnityAreaSelected] = useState(0);
  const [blockFields, setBlockFields] = useState({
    client: false,
    farm: false,
    cropYear: false,

  });
  const [tabContentHeigh, setTabContentHeigh] = useState('47vh');
  let { assemblyLineId } = useParams();
  const token = localStorage.getItem('token');
  let history = useHistory();
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  const toasts = useToasts();
  const queryParams = new URLSearchParams(window.location.search);
  let pitFarmData = {
    action: ''
  };
  if (queryParams.get('action') === "pitFiledBack") {
    pitFarmData = {
      action: queryParams.get('action'),
      clientId: parseInt(queryParams.get('clientId')),
      farmId: parseInt(queryParams.get('farmId')),
      farmerId: parseInt(queryParams.get('farmerId')),
      assemblyLinePitFarmId: parseInt(queryParams.get('assemblyLinePitFarmId')) === NaN ? 0 : parseInt(queryParams.get('assemblyLinePitFarmId'))
    }
    cleanQueryInUrl();
    if (pitFarmData.assemblyLinePitFarmId) {
      assemblyLineId = pitFarmData.assemblyLinePitFarmId
    }
  }

  if (assemblyLineId === "0" || assemblyLineId === undefined || assemblyLineId === null) {
    pitFarmData.action = "pitFarmAdd"
  }
  else {
    pitFarmData.action = "pitFarmEdit"
  }

  function cleanQueryInUrl() {
    var query = window.location.search.substring(1);
    if (query.length) {
      if (window.history !== undefined && window.history.pushState !== undefined) {
        window.history.pushState({}, document.title, window.location.pathname);
      }
    }
  }

  async function loadAssemblyLine(assemblyLineId, pitFarmData) {
    if (pitFarmData.action === "pitFarmAdd") {
      return null;
    }
    try {
      const response = await axios.get(`${host}/pits-farms/${assemblyLineId}?withFieldList=true`);
      setAssemblyLine(response.data)
      return response.data;
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  async function loadClient(assemblyLine, pitFarmData) {
    let clientId = 0;
    if (pitFarmData.action === "pitFarmAdd") {
      clientId = pitFarmData.clientId ? pitFarmData.clientId : 0;
    }
    else {
      clientId = assemblyLine ? assemblyLine.workstations[0].input.clientId : 0;
    }
    
    try {
      let _response = await axios.get(`${host}/clients`);
      let clientes = _response.data.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      })
      setOptionsClient(clientes)
      if (clientId === 0)
        return null;
      return clientes.find((client) => { if (client.id === clientId) { return client } });
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
      console.log(e.response);
    }
  }

  async function loadFields(assemblyLine, client, farmId) {
    setLoading({ ...loading, area: true });
    let clientId = 0, workstationInitialInput = null;
    if (pitFarmData.action === "pitFarmAdd") {
      if (pitFarmData.clientId) {
        clientId = pitFarmData.clientId;
        farmId = pitFarmData.farmId;
      }
      else {
        if (client) {
          clientId = client.id;
        }
        else {
          setLoading({ ...loading, area: false });
          return;
        }
      }

    }
    else {
      workstationInitialInput = assemblyLine.workstations[0].input;
      clientId = client === undefined ? workstationInitialInput.client : client.id;
      farmId = workstationInitialInput.farmId;
    }
    try {
      const responseArea = await axios.get(`${host}/pits-farms/fields?clientId=${clientId}&farmId=${farmId}`);
      const listAreas = responseArea.data;
      let totalArea = totalAreaSelected, totalUnityArea = unityAreaSelected;
      if (pitFarmData.action === "pitFarmEdit") {
        for (let i = 0; i < listAreas.length; i++) {
          for (let m = 0; m < listAreas[i].areas.length; m++) {
            let area = listAreas[i].areas[m];
            area.useHarvest = true;
            for (let j = 0; j < workstationInitialInput.fields.length; j++) {
              let fieldId = workstationInitialInput.fields[j];
              if (area.id === fieldId) {
                if (listAreas[i].areas[m].hasData === true && (listAreas[i].areas[m].errorMessage === '' || listAreas[i].areas[m].errorMessage === null)) {
                  listAreas[i].areas[m].checked = true;
                  totalArea += area.totalArea;
                  totalUnityArea += 1;
                }
              }
            }//for j
          }//for m
        }//for i
      }
      setUnityAreaSelected(totalUnityArea);
      setTotalAreaSelected(Math.round(totalArea * 100) / 100);
      setLoading({ ...loading, area: false });
      setListField(listAreas);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({title: 'Erro!', message: 'Deu um erro ao baixar os dados dos PIT Talhões!'})
      setLoading({ ...loading, area: false });
      console.log(e.response);
    }
  }

  async function loadFarm(assemblyLine, client, pitFarmData) {
    let farmId = 0;
    if (pitFarmData.action === "pitFarmAdd") {
      farmId = parseInt(pitFarmData.farmId);
    } else {
      if (assemblyLine === null)
        return;
      const workstationInitialInput = assemblyLine.workstations[0].input;
      farmId = workstationInitialInput.farmId;
    }
    if (client) {
      if (client.farms === undefined) {
        try {
          let _response = await axios.get(`${host}/clients/${client.id}/farms`);
          client.farms = _response.data || []
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
          setLoading({ ...loading, farm: false });
          console.log(e.response);
          return;
        }
      }
      client.farms = client.farms.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          ...elem
        }
      });
      setOptionsFarm(client.farms);
    }
    if (farmId) {
      if (client) {
        for (let i = 0; client.farms.length; i++) {
          if (client.farms[i].id === farmId) {
            setLoading({ ...loading, farm: false });
            return client.farms[i];
          }
        }
      }
    }
    setLoading({ ...loading, farm: false });
  }

  async function loadScreen(assemblyLineId, pitFarmData) {
    setLoading({ ...loading, client: true, farm: true });
    if (pitFarmData.action === "pitFarmEdit") {
      blockFieldsToEdit(true);
    }
    const _assemblyLine = await loadAssemblyLine(assemblyLineId, pitFarmData);
    let client = await loadClient(_assemblyLine, pitFarmData);
    setLoading({ ...loading, client: false });
    let farm = await loadFarm(_assemblyLine, client, pitFarmData)
    await loadFields(_assemblyLine, client, 0);
    setClient(client);
    setFarm(farm);
    setLoading({ ...loading, client: false, farm: false });
  }

  function showMessage(title, message, type) {
    if (type === 'danger') {
      toasts.danger({title, message})
      return
    }
    if (type === 'success') {
      toasts.success({ title, message: message })
      return
    }
    if (type === 'warning') {
      toasts.warning({ title, message: message })
      return
    }
    toasts.info({ title, message: message })
  }

  useEffect(() => {
    loadScreen(assemblyLineId, pitFarmData);
  }, []);

  function getFieldsSelected() {
    let areas = [], fields = [], statistics = {}, harvests = {}
    let area = null;
    for (let i = 0; i < listField.length; i++) {
      areas = listField[i].areas;
      for (let j = 0; j < areas.length; j++) {
        area = areas[j];
        if (area.checked) {
          fields.push({id: area.id, cropYear: area.cropYear});
          statistics[area.id] = area.statistics
          harvests[area.id] = area.useHarvest ? area.harvest : area.offSeason.length > 0 ? area.offSeason[0] : area.harvest
        }
      }
    }
    return {fields, statistics, harvests};
  }

  function fillObjectToSend() {
    const fieldData = getFieldsSelected();
    return {
      assemblyLineId: assemblyLine ? assemblyLine.id : 0,
      clientId: client.id === undefined || client.id === 0 ? "" : client.id,
      farmId: farm.id === 0 ? "" : farm.id,
      ...fieldData
    }
  }

  function blockFieldsToEdit(canBlock) {
    setBlockFields({
      client: canBlock,
      farm: canBlock,
      cropYear: false,
    })
  }

  async function onSubmit() {
    // setLoading({...loading, submit:true});
    try {
      let object_to_send = fillObjectToSend();

      if (has_empty_fields(object_to_send)) {
        setLoading({ ...loading, submit: false });
        return;
      }
      if (assemblyLineId === undefined) {
        try {
          await axios.post(`${host}/pits-farms`, object_to_send);
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao atualizar o PIT Fazenda' });
          setLoading({ ...loading, submit: false });
          console.log(e.response);
          return;
        }
      }
      else {
        try {
          await axios.put(`${host}/pits-farms/${assemblyLineId}`);
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao criar UM PIT Fazenda!' });
          setLoading({ ...loading, submit: false });
          console.log(e.response);
          return;
        }
      }
      showMessage('Sucesso', 'Cadastrado com sucesso', 'success')
      history.push("/pit/farm/");
    } catch (error) {
      console.log(error)
      showMessage('Erro', 'Erro ao criar pit farm', 'danger')
    }
    setLoading({...loading, submit:false});
  }

  function has_empty_fields(data_to_send) {
    let error = false;
    const _type = 'warning';
    const title = 'Campo vazio';

    if (data_to_send.farmId === "" || data_to_send.farmId === undefined) {
      showMessage(title, 'Selecione uma fazenda', _type);
      error = true;
    }
    if (getFieldsSelected().fields.length === 0) {
      showMessage(title, 'Selecione uma talhão', _type);
      error = true;
    }
    return error;
  }

  async function fetchClientFarms(clientId) {
    try {
      const response = await axios.get(`${host}/clients/${clientId}/farms`);
      let cont = 1;
      response.data = response.data.map(elem => {
        let retorno = {
          value: elem.id,
          label: elem.name,
          clientId: clientId,
          ...elem
        }
        cont++;
        return retorno
      })
      setOptionsFarm(response.data);
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter as fazendas' });
      console.log(e.response);
    }
  }

  const clientChangeHandler = (event) => {
    setLoading({ ...loading, farm: true });
    const optionId = event.value;
    if (optionId === 0) {
      setOptionsFarm([{ id: 0, name: "selecione uma fazenda" }]);
      setFarm({})
      return;
    }
    var _client = optionsClient.find((optionValue) => { if (optionValue.value === optionId) { return optionValue } });
    if (_client === undefined) {
      setLoading({ ...loading, farm: false });
      return;
    }
    setClient(_client);
    setUnityAreaSelected(0);
    setTotalAreaSelected(0);
    setListField([]);
    setLoading({ ...loading, farm: false });
    fetchClientFarms(event.value);
  }

  const farmChangeHandler = async (event) => {
    setLoading({ ...loading, area: true });
    try {
      let option = event;
      let optionId = option.id;
      if (optionId === "0" || optionId === undefined) {
        setLoading({ ...loading, area: false });
        setFarm({ id: 0 });
        setListField([]);
        return;
      }
      const farmId = parseInt(optionId);
      if (farmId) {
        var farm = optionsFarm.find((farm) => { if (farm.id === farmId) { return farm } });
        setFarm(farm);
        let clientId = client.id;
        try {
          const responseArea = await axios.get(`${host}/pits-farms/fields?clientId=${clientId}&farmId=${farmId}`);
          setListField(responseArea.data);
        }
        catch (e) {
          if (e.message === 'Request failed with status code 401') {
            window.location = process.env.REACT_APP_DASHBOARD_URL;
            return;
          }
          toasts.danger({ title: 'Erro!', message: 'Erro ao obter os dados da Fazenda' });
          setLoading({ ...loading, area: false });
          console.log(e.response);
        }
      }
      setUnityAreaSelected(0);
      setTotalAreaSelected(0);
    }
    catch (e) {
      toasts.danger({title: 'Erro!', message: 'Deu um erro ao baixar os dados dos PIT Talhões!'})
      console.log(e);
    }
    setLoading({ ...loading, area: false });
  }

  const toggleFieldUseHarvest = (fieldIndex, areaIndex) => {
    listField[fieldIndex].areas[areaIndex].useHarvest = !listField[fieldIndex].areas[areaIndex].useHarvest;
    setListField(listField);
    forceUpdate();
  }

  const selectFieldChangeHandler = (i, j, value) => {
    let area = listField[i].areas[j];
    if (area.hasData === false) {
      showMessage('Alerta', 'Não é possível selecionar um talhão sem dados', 'warning')
      return;
    }

    let checked = !area.checked;
    if (value !== undefined) {
      checked = value;
      listField[i].allChecked = value;
    }
    listField[i].areas[j].checked = checked;
    if (value !== undefined) {
      return;
    }
    const totals = calculateTotals(area);
    updateTotals(totals);
  }

  const updateTotals = (totals) => {
    if (totals.totalArea < 0) {
      totals.totalArea = 0;
    }
    if (totals.totalUnityArea < 0) {
      totals.totalUnityArea = 0;
    }
    setUnityAreaSelected(totals.totalUnityArea);
    setTotalAreaSelected(totals.totalArea);
    setListField(listField);
    forceUpdate();
  }

  const calculateTotals = (area, totals) => {
    if (totals === undefined) {
      totals = {totalArea: totalAreaSelected, totalUnityArea: unityAreaSelected}
    }
    const signal = area.checked ? 1 : -1;
    totals.totalArea += (area.totalArea * signal);
    totals.totalUnityArea += (1 * signal);
    return totals;
  };

  const editFieldHandler = (area) => {
    let farmerId = client.farmer_id ? client.farmer_id : client.farmerId;
    history.push(`/pit/field/cad?action=pitFarmEdit&assemblyLinePitFarmId=${assemblyLineId ? assemblyLineId : 0}&clientId=${client.id}&farmerId=${farmerId}&farmId=${farm.id}&fieldId=${area.id}&cropYearArea=${area.cropYear}&assemblyLineId=${area.assemblyLineId}`);
  }

  const addDataOnFieldHandler = (area) => {
    let farmerId = client.farmer_id ? client.farmer_id : client.farmerId;
    history.push(`/pit/field/cad?action=pitFarmAdd&assemblyLinePitFarmId=${assemblyLineId ? assemblyLineId : 0}&clientId=${client.id}&farmerId=${farmerId}&farmId=${farm.id}&fieldId=${area.id}&cropYearArea=${area.cropYear}`);
  }
  
  const buildTabsList = () => {
    const tabs = [];
    let field = null;
    for (let i = 0; i < listField.length; i++) {
      field = listField[i];
      tabs.push((<Tabs.Tab key={i} tabId={'tabCropYear' + i}>{field.cropYear}</Tabs.Tab>));
    }
    return tabs;
  };

  const selectAllFieldChangeHandler = (fieldIndex) => {
    const field = listField[fieldIndex];
    field.allChecked = !field.allChecked;
    const totals = {totalArea: totalAreaSelected, totalUnityArea: unityAreaSelected};
    for (let areaIndex = 0; areaIndex < field.areas.length; areaIndex++) {
      const area = field.areas[areaIndex]
      if (disableSwitch(area)) {
        continue;
      }
      if (field.allChecked && area.checked) {
        continue;
      }
      selectFieldChangeHandler(fieldIndex, areaIndex, field.allChecked);
      calculateTotals(area, totals);
    }

    updateTotals(totals);
  }

  const buildTabsPanels = () => {
    const tabsContents = [];
    let field = null;
    for (let i = 0; i < listField.length; i++) {
      field = listField[i];
      tabsContents.push((
        <Tabs.Panel key={i} tabId={'tabCropYear' + i} padding="major-2" className={formClasses['tabs-panel-content']} style={{height: tabContentHeigh}}>
          <Table isResponsive hasDividers  isHoverable variant="shadowed">
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>
                  <Switch
                    className={formClasses['check-area']}
                    palette="success"
                    title="Selecionar Todos"
                    onChange={(event) => { selectAllFieldChangeHandler(i) }}
                    checked={field.allChecked}
                  />
                </Table.HeadCell>
                <Table.HeadCell>Nome</Table.HeadCell>
                <Table.HeadCell>Quadra</Table.HeadCell>
                <Table.HeadCell>Safra - Cultura</Table.HeadCell>
                <Table.HeadCell>Safra -Produtividade</Table.HeadCell>
                <Table.HeadCell>Safra - Variedade</Table.HeadCell>
                <Table.HeadCell>Safra - Data de plantio</Table.HeadCell>
                <Table.HeadCell>Safrinha - Cultura</Table.HeadCell>
                <Table.HeadCell>Safrinha - Produtividade</Table.HeadCell>
                <Table.HeadCell>Safrinha - Data de plantio</Table.HeadCell>
                <Table.HeadCell>Safrinha - Variedade</Table.HeadCell>
                <Table.HeadCell>Área (ha)</Table.HeadCell>
                <Table.HeadCell />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              { showRows(field, i) }
            </Table.Body>
          </Table>
        </Tabs.Panel>
      ));
    }
    return tabsContents;
  }

  function disableSwitch(area) {
    if (area.hasData === false) {
      return true;
    }
    if (area.errorMessage !== null && area.errorMessage !== '') {
      return true;
    }
    return false;
  }

  const showRows = (field, i) => {
    let rows = [], area=null;
    for (let j=0; j< field.areas.length ; j++) {
      area = field.areas[j];
      rows.push(
        (
          <Table.Row key={j}>
            <Table.Cell>
              <Switch
                className={formClasses['check-area']}
                palette="success"
                disabled={ disableSwitch(area)}
                onChange={(event)=>{ selectFieldChangeHandler(i,j) }}
                checked={listField[i].areas[j].checked}
                style={{marginBottom: 5}}
              />
              <Switch
                className={formClasses['check-area']}
                disabled={ disableSwitch(area)}
                state="warning"
                label={listField[i].areas[j].useHarvest ? 'Safra' : 'Safrinha'}
                onChange={(event) => { toggleFieldUseHarvest(i, j) }}
                checked={listField[i].areas[j].useHarvest}
              />
            </Table.Cell>
            <Table.Cell>{area.name}</Table.Cell>
            <Table.Cell>{area.block}</Table.Cell>
            <Table.Cell>{area.harvest ? area.harvest.crop: ''}</Table.Cell>
            <Table.Cell>{area.harvest ? area.harvest.targetYield: ''}</Table.Cell>
            <Table.Cell>{area.harvest ? area.harvest.varietyLabel: ''}</Table.Cell>
            <Table.Cell>{area.harvest ? area.harvest.plantingDate: ''}</Table.Cell>
            <Table.Cell>{area.offSeason && area.offSeason.length > 0 ? area.offSeason[0].crop: ''}</Table.Cell>
            <Table.Cell>{area.offSeason && area.offSeason.length > 0 ? area.offSeason[0].targetYield: ''}</Table.Cell>
            <Table.Cell>{area.offSeason && area.offSeason.length > 0 ? area.offSeason[0].plantingDate: ''}</Table.Cell>
            <Table.Cell>{area.offSeason && area.offSeason.length > 0 ? area.offSeason[0].varietyLabel: ''}</Table.Cell>
            <Table.Cell>
              {area.totalArea}
            </Table.Cell>
            <Table.Cell>
              <Flex>
                {area.hasData === false &&
                  <Button
                    palette="warning"
                    onClick={() => { addDataOnFieldHandler(listField[i].areas[j]) }}
                  >
                    Adicionar dados
                  </Button>
                }
                {area.hasData &&
                  <Box onClick={() => { editFieldHandler(listField[i].areas[j]) }} >
                    <Icon
                      aria-label="Settings"
                      icon={faPen}
                      type="font-awesome"
                    />
                    &nbsp; Editar
                  </Box>
                }
                {listField[i].areas[j].errorMessage &&
                  <Popover.State placement="left-start" unstable_fixed={true}>
                    <Popover.Disclosure use={Button}>
                      <Icon aria-label="Error" icon="danger" color="danger" marginLeft="major-1" />
                    </Popover.Disclosure>
                    <Popover hasArrow>
                      {listField[i].areas[j].errorMessage}
                    </Popover>
                  </Popover.State>
                }
              </Flex>
            </Table.Cell>
          </Table.Row>
        )
      );
    }
    return rows;
  }

  const openCollapsible = () => {
    setTabContentHeigh('47vh');
  }

  const closeCollapsible = () => {
    setTabContentHeigh('68vh');
  }

  return (
    <form >
      <div className={generalClasses['page-container']}>
        <Box alignX="center" className={generalClasses['top-buttons']}>
          <Flex>
            {!loading.submit && <Button palette="success" type="button" onClick={onSubmit}>Salvar</Button>}
            <Box width="30px" />
            {loading.submit && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
            <Box width="30px" />
            <Button palette="gray" onClick={() => history.push("/pit/farm")}> Voltar</Button>
          </Flex>
        </Box>
        <Divider/>

        <Collapsible
          className={formClasses['selects-client-farm-container']}
          openedClassName={formClasses['selects-client-farm-container']}
          trigger={(
            <div>
              <span>PIT - Fazenda</span> >>
              <span className={formClasses['collapsible-trigger-title']}>Cliente: </span><span className={formClasses['collapsible-trigger-text']}>{client ? client.name : 'Não Selecionado'}</span> /
              <span className={formClasses['collapsible-trigger-title']}>Fazenda: </span><span className={formClasses['collapsible-trigger-text']}>{farm ? farm.name : 'Não Selecionado'}</span> |
              <span className={formClasses['collapsible-trigger-title']}>Selecionados: </span><span className={formClasses['collapsible-trigger-text']}>Hectares: {totalAreaSelected} - Talhões: {unityAreaSelected}</span>
              <span className={formClasses['collapsible-trigger-icon']} />
            </div>
          )}
          triggerWhenOpen={(
            <div>
              <span>PIT - Fazenda</span>
              <span className={formClasses['collapsible-trigger-icon']} />
            </div>
          )}
          triggerTagName="div"
          triggerClassName={formClasses['collapsible-title']}
          triggerOpenedClassName={formClasses['collapsible-title-is-open']}
          overflowWhenOpen="unset"
          transitionTime="100"
          open={true}
          onOpen={openCollapsible}
          onClose={closeCollapsible}
        >
          <div className={formClasses['collapsible-content-inner']}>
            <div>
              <Flex>
                <Box width="70px" height="50px"  >
                  <label>Cliente:&nbsp;</label>
                </Box>
                <Box width="400px" height="50px" >
                  <Select placeholder="" value={client} options={optionsClient} onChange={clientChangeHandler} />
                </Box>
                {loading.client && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
              </Flex>
            </div>

            <div>
              <Flex>
                <Box width="70px" height="50px"  >
                  <label>Fazenda:&nbsp;</label>
                </Box>
                <Box width="400px" height="50px" >
                  <Select options={optionsFarm} value={farm} onChange={farmChangeHandler} placeholder="" />
                </Box>
                {loading.farm && <Spinner color="success" size="medium" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
              </Flex>
            </div>

            <div>
              <label>Total area selecionada: {totalAreaSelected}</label>
            </div>

            <div>
              <label>Talhões selecionados: {unityAreaSelected}</label>
            </div>
          </div>
        </Collapsible>

        <div>
          {loading.area &&
            <div>
              <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>
            </div>
          }

          <Tabs>
            <Tabs.List>
              {buildTabsList()}
            </Tabs.List>
            {buildTabsPanels()}
          </Tabs>
        </div>
      </div>
    </form>
  );
};

export default PitFarmForm;