import React, { useContext } from 'react';
import AuthContext from '../../../store/auth-context';
import {  TopNav, Button, Image} from 'bumbag';

import logo from '../../../images/logo-inceres.png'
import classes from '../../../css/MainHeader.module.css'

const MainHeader = (props) => {

  const authCtx = useContext(AuthContext);
  return (
    <TopNav className={classes['main-header']}>
    <TopNav.Section>
      <TopNav.Item href="" fontWeight="semibold">
        <Image 
        src={logo}
        height="50px"
        width="100px"
        />
      </TopNav.Item>
      <TopNav.Item >PIT Admin</TopNav.Item>
    </TopNav.Section>
    <TopNav.Section marginRight="major-2">
      { authCtx.isLoggedIn  && <TopNav.Item>
        <Button variant="ghost" onClick={authCtx.logout} >Sair</Button>
      </TopNav.Item> }
    </TopNav.Section>
  </TopNav>
  );
};

export default MainHeader;
