import { useContext } from 'react';
import {  BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import MainHeader from './components/UI/MainHeader/MainHeader';
import Home from './pages/Home'
import PitFieldList from './pages/PitFieldList'
import PitFarmList from './pages/PitFarmList'
import PitfieldCad from './pages/PitfieldCad'
import PitFarmCad from './pages/PitFarmCad'
import Fertility from './pages/Fertility'
import PitFarmResult from './pages/PitFarmResult'
import AuthPage from './pages/AuthPage'
import NotFound from './pages/NotFound'
import { Provider} from 'bumbag';
import AuthContext from './store/auth-context';
import MainTheme from './components/UI/MainTheme/mainTheme'
import { ToastManager } from 'bumbag';

import IndexVegetation from "./pages/IndexVegetation";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PitFieldEtapas from "./pages/PitFieldEtapas";
import PitFarmEtapas from "./pages/PitFarmEtapas";

function App() {
  const authCtx = useContext(AuthContext);

  return (
    <Provider theme={MainTheme}>
        <MainHeader></MainHeader>
        <main>
            <Router>
                <Switch>
                    <Route path='/fertility' exact><Fertility></Fertility></Route>
                    <Route path='/indexvegetation' exact><IndexVegetation></IndexVegetation></Route>
                    <Route path='/pit/farm' exact><PitFarmList></PitFarmList></Route>
                    <Route path='/pit/farm/cad' exact><PitFarmCad></PitFarmCad></Route>
                    <Route path='/pit/farm/cad/:assemblyLineId' exact><PitFarmCad></PitFarmCad></Route>
                    <Route path='/pit/farm/result/:assemblyLineId' exact><PitFarmResult></PitFarmResult></Route>

                    <Route path='/pit/field' exact><PitFieldList></PitFieldList></Route>
                    <Route path='/pit/field/cad' exact><PitfieldCad></PitfieldCad></Route>
                    <Route path='/pit/field/cad/:assemblyLineId' exact><PitfieldCad></PitfieldCad></Route>
                    <Route path='/pit/field/etapas/:assemblyLineId' exact><PitFieldEtapas></PitFieldEtapas></Route>
                    <Route path='/pit/farm/etapas/:assemblyLineId' exact><PitFarmEtapas></PitFarmEtapas></Route>

                    <Route path='/forgotpassword' exact><ForgotPassword></ForgotPassword></Route>
                    <Route path='/resetpassword' exact><ResetPassword></ResetPassword></Route>

                    <Route path='/' exact><Home /></Route>

                    <Route path='*'><NotFound></NotFound></Route>
                </Switch>
        </Router>
        </main>
        <ToastManager />
    </Provider>
  );
}

export default App;