import React, { Fragment } from 'react';
import PitFarmForm from '../components/pitFarm/pitFarmForm'
const PitFieldCad = (props) => {
  return (
    <Fragment>
      <PitFarmForm></PitFarmForm>
    </Fragment>
  );
};

export default PitFieldCad;
