import React, { Fragment } from 'react';
import PitFieldForm from '../components/pitfield/pitFieldForm'
import { Divider } from 'bumbag'
const PitfieldCad = (props) => {
  return (
    <Fragment>
        <Divider></Divider>
      <PitFieldForm></PitFieldForm>
    </Fragment>
  );
};

export default PitfieldCad;
