import {
  useToasts,
  Button,
  Divider,
  Flex,
  Box,
  Card, Spinner
} from "bumbag";

import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {JSONEditor} from "react-json-editor-viewer";

import generalClasses from '../css/General.module.css';
import pitListClasses from '../css/PitList.module.css';

const PitFarmEtapas = () => {
  let history = useHistory();
  const [listaWorkstation, setListaWorkstation] = useState([]);
  const token = localStorage.getItem('token');
  const host = process.env.REACT_APP_EASY_FARM_API_URL;
  let {assemblyLineId} = useParams();
  const [loading, setLoading] = useState({
    workstations: true,
  });
  const toasts = useToasts();

  async function carregaEtapas() {
    let elem, i, workstation;
    if (listaWorkstation.length === 0) {
      const assemblyline = await fetchAssemblyLine(assemblyLineId, true);
      for (i = 0; i < assemblyline.workstations.length; i++) {
        elem = assemblyline.workstations[i];
        workstation = await fetchWorkstation(assemblyLineId, assemblyline.blueprintId, elem.id);
        workstation.showStartResult = false;
        workstation.assemblyLineId = assemblyline.id;
        workstation.showEndResult = false;
        assemblyline.workstations[i] = workstation;
      }
      setListaWorkstation(assemblyline.workstations);
      setLoading({workstations: false})
    }
  }

  useEffect(async () => {
    await carregaEtapas();
  }, []);

  async function fetchAssemblyLine(assemblyLineId, workstationResumida) {
    if (assemblyLineId) {
      try {
        const response = await axios.get(`${host}/pits-farms/${assemblyLineId}?workstationResumida=${workstationResumida}`);
        return response.data
      }
      catch (e) {
        if (e.message === 'Request failed with status code 401') {
          window.location = process.env.REACT_APP_DASHBOARD_URL;
          return null;
        }
        toasts.danger({ title: 'Erro!', message: 'Erro ao obter o PIT' });
        console.log(e.response);
        return null;
      }
    }
    return null;
  }

  async function fetchWorkstation(assemblyLineId, blueprintId, workstationId) {
    try {
      const response = await axios.get(`${host}/pits-farms/${assemblyLineId}/steps/${workstationId}`);
      return response.data
    }
    catch (e) {
      if (e.message === 'Request failed with status code 401') {
        window.location = process.env.REACT_APP_DASHBOARD_URL;
        return;
      }
      toasts.danger({ title: 'Erro!', message: 'Erro ao obter a etapa do PIT' });
      console.log(e.response);
    }
  }

  function showJson(dados, id, _workstation) {
    return <div className={generalClasses['json-editor']}><JSONEditor data={dados} collapsible view="dual" /></div>
  }

  function alertaStatus(id, tipo) {
    let lista = listaWorkstation.map(elem => {
      if (elem.id === id) {
        if (tipo === 'entrada')
          elem.showStartResult = !elem.showStartResult;
        else
          elem.showEndResult = !elem.showEndResult;
      }
      return elem;
    })
    setListaWorkstation(lista);
  }

  const cardsArray = listaWorkstation.map(_workstation => (
    <Card standalone className={pitListClasses['list-pit-item']} key={_workstation.id}>
      <Card.Header>
        <Card.Title>Etapa: {_workstation.name} </Card.Title>
      </Card.Header>
      <Card.Content>
        <label>Id: {_workstation.id}</label><br></br>
        <label>Status: {_workstation.status}</label><br></br>
        <label>Ordem de execução:{_workstation.executionOrder}</label><br></br><br></br>

        <button onClick={() => alertaStatus(_workstation.id, 'entrada')}>Entrada da etapa</button>
        <br></br>
        {_workstation.showStartResult && showJson(_workstation.input, `workstation_input_${_workstation.id}`, _workstation)} <br></br>
        <button onClick={() => alertaStatus(_workstation.id, 'saida')}>Saída da etapa</button>
        <br></br>
        {_workstation.showEndResult && showJson(_workstation.output, `workstation_output_${_workstation.id}`, _workstation)} <br></br>
      </Card.Content>
      <Card.Footer>
      </Card.Footer>
    </Card>
  ));

  return (
    <div className={generalClasses['page-container']}>
      <Box alignX="center" className={generalClasses['top-buttons']}>
        <Flex>
          <Button palette="gray" onClick={() => history.push("/pit/farm")}> Voltar</Button>
        </Flex>
      </Box>

      <Divider/>
      {loading.workstations && <Spinner className={generalClasses['spinner-position']} color="success" fontSize="80px" perimeter="90%" hasTrack trackColor="green" duration="1.2s"/>}
      {cardsArray}
    </div>
  );
};

export default PitFarmEtapas;

