import React, { Fragment } from 'react';
import FertilityForm from '../components/fertility/fertilityForm'
const Fertility = (props) => {
  return (
    <Fragment>
      <FertilityForm></FertilityForm>
    </Fragment>
  );
};

export default Fertility;
