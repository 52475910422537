import React, { useState , useEffect} from 'react';
import { Formik, Field} from 'formik';
import {Button, Flex, Spinner} from 'bumbag'
import axios from 'axios';
import {useHistory, useParams} from "react-router-dom";
import { useToasts , Box, Divider} from 'bumbag'

const PitFieldForm = (props) => {
    const [client, setClient] = useState({id:0});
    const [farm, setFarm] = useState({id: 0});
    const [field, setField] = useState({id: 0});
    const [cropYear, setCropYear] = useState({id: 0});
    const [fertilityLabel, setFertilityLabel] = useState({id: 0});
    const [fertilities, setFertilities] = useState({id: 0});
    const [cycleId, setCycleId] = useState(0);
    const [cropDepth, setCropDepth] = useState({id: 0});

    const [interpolation, setInterpolation] = useState({id: 0});

    const [optionsCropYear, setOptionsCropYear] = useState([]);
    const [optionsClient, setOptionsClient] = useState([]);
    const [optionsFarm, setOptionsFarm] = useState([]);
    const [optionsField, setOptionsField] = useState([]);
    const [optionsFertility, setOptionsFertility] = useState([]);
    const [optionsCropDepth, setOptionsCropDepth] = useState([]);
    const host = process.env.REACT_APP_EASY_FARM_API_URL;
    const token = localStorage.getItem('token');
    let history = useHistory();
    const toasts = useToasts();
    const UrlData = null;
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    };
    async function fetchCropYear() {
        const response = await axios.get(`${host}/harvest-years`, {headers});
        if (response.status !== 200){
           console.log('Erro load crop year')
           return;
        }
        setOptionsCropYear([''].concat(response.data));
    }

    async function fetchClient(){
         const response =  await axios.get(`${host}/clients`, {headers});
         setOptionsClient([{id:0, name:'selecione um cliente'} ].concat(response.data))
    }


    async function fetchField(farmId, _cropYear){
        const response =  await axios.get(`${host}/farms/${farmId}/areas?year=${_cropYear}`, {headers});
        if (response.status !== 200){
           console.log('Erro load crop year')
           return;
        }
        setOptionsField([{id: 0, name:''}].concat(response.data));
    }

    async function loadClient(UrlData){
        let _response =  await axios.get(`${host}/clients`, {headers});
        setOptionsClient([{id:0, name:'Selecione um cliente'} ].concat(_response.data))
        let _clientId =0;
        let _client  = null;
        if( _clientId > 0){
            _client = _response.data.find((_client)=> { if (_client.id === _clientId){return _client}} );
            setClient({id: _clientId, farmerId:_client.farmer_id});
        }
        return _client;
    }
    async function loadFarm(client, UrlData){
        let farmId = 0;
        if(client === null){
            return;
        }
        setOptionsFarm([{id:0, name:"selecione uma fazenda"}].concat(client.farms));
        setFarm({ id: farmId });
    }
    async function loadFields(UrlData){
         let farmId = null;
         let fieldId = null;
         let _cropYear = null;
        const _response_field =  await axios.get(`${host}/farms/${farmId}/areas?year=${_cropYear}`, {headers});
        if (_response_field.status !== 200){
           return;
        }
        setOptionsField([{id: 0, name:''}].concat(_response_field.data));
        setField({id: fieldId});
    }

    async function loadScreen(UrlData){
        fetchClient();
        fetchCropYear();
    }

    function showMessage(title, message, type){
        if(type === 'danger'){
            toasts.danger({ title, message })
            return
        }
         if(type === 'success'){
            toasts.success({ title, message: message })
            return
         }
         if(type === 'warning'){
            toasts.warning({ title, message: message })
            return
         }
         toasts.info({ title, message: message })

    }

    useEffect( async ()=>{
        await loadScreen(UrlData)
    },[]);

    const clientChangeHandler = (event) => {
        const optionId = event.target.value;
        if(optionId === 0 ){
            setOptionsFarm([{id:0, name:"selecione uma fazenda"}]);
            setFarm({})
            return;
        }
        var _client = optionsClient.find((optionValue)=> { if (optionValue.id === optionId){return optionValue}} );
        if(_client === undefined)
            return;
        setClient(_client);
        setOptionsFarm([{id:0, name:"selecione uma fazenda"}].concat(_client.farms));
    }
    const farmChangeHandler = (event) => {
        let optionId = event.target.value;
        if(optionId === "0" || optionId === undefined ){
            setOptionsFarm([{id:0, name:"selecione uma fazenda"}]);
            setFarm({})
            return;
        }
        console.log(event.target.value)
        const farmId = parseInt(event.target.selectedOptions[0].value);
        if(farmId){
           var farm = optionsFarm.find((farm)=> { if (farm.id === farmId){return farm}} );
           setFarm(farm);
           console.log('farm', farm)
        }
    }
    const cropYearChangeHandler = async (event) => {
        setCropYear(event.target.value);
        await fetchField(farm.id, event.target.value);
    }
    const fieldChangeHandler = async (event) => {
        let areaId = event.target.value;
        if(areaId === "0"){
            setField({id:0});
            setCropDepth([]);
            setFertilityLabel([]);
            setCycleId(0);
            setInterpolation("");
            setOptionsFertility([])
            setOptionsCropDepth([])
            return;
        }

        setField({id:event.target.value});
        let clientId = client.sm_id;
        let farmId = farm.sm_id;

        const response =  await axios.get(
            `${host}/fertilities?clientId=${clientId}&farmId=${farmId}&areaId=${areaId}&cropYear=${cropYear}`, {headers});
        if (response.status !== 200){
            throw Error('Erro on request')
        }
        setFertilities(response.data[0].interpolacoes);
        setOptionsFertility(response.data[0].listFertility);
        setOptionsCropDepth(response.data[0].listDepth);

        setCropDepth(response.data[0].listDepth[0]);
        setFertilityLabel(response.data[0].listFertility[0])
        setCycleId(response.data[0].id);
    }
    async function getImage(clientId, farmId, areaId, fertilityId, _cycleId){
        let url = `${host}/fertilities/${fertilityId}?clientId=${clientId}&farmId=${farmId}&areaId=${areaId}&cropYear=${cropYear}&cycleId=${cycleId}`
        const response = await axios.get(url, {headers});
        response.data.raster = `http://local-rmais.inceres.com.br/cache/${response.data.raster}`
        setInterpolation(response.data);
    }

    const fertilityChangeHandler = async (event) => {
        const _fertilityLabel = event.target.value;
        let clientId = client.sm_id;
        let farmId = farm.sm_id;
        let areaId = field.id;
        let _fertility = fertilities.find((item)=>{
            if(item.attr === _fertilityLabel && cropDepth === item.profundidade)
                return true;
            return false;
        });
        const fertilityId = _fertility.id;

        await getImage(clientId, farmId, areaId, fertilityId, cycleId);
        setFertilityLabel(_fertilityLabel);
    }
    const optionsCropDepthChangeHandler = async (event) =>{
        const _cropDepth = event.target.value;
        const clientId = client.sm_id;
        const farmId = farm.sm_id;
        const areaId = field.id;
        const _fertility = fertilities.find((item)=>{
            if(item.attr === fertilityLabel && _cropDepth === item.profundidade)
                return true;
            return false;
        });
        const fertilityId = _fertility.id;
        await getImage(clientId, farmId, areaId, fertilityId, cycleId);
        setCropDepth(_cropDepth);
    }
    const buttonBackChangeHandler = () => {
        history.push("/")
    }

    return (
        <Formik
            initialValues={{checked: []}}
            render={ ({values,   touched, handleSubmit, errors,   handleChange,
                handleBlur,}) => (
                <form onSubmit={handleSubmit} >
                    <h3>Dados de coleita e plantio</h3>
                    <div>
                        <label>Cliente:&nbsp;</label>
                        <Field
                            value={client.id}
                            onChange={clientChangeHandler}
                            name="optionsClient"
                            as="select"
                        >
                            {
                                optionsClient.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Fazenda:&nbsp;</label>
                        <Field name="optionsFarm" as="select"
                               value={farm.id}
                               onChange={farmChangeHandler}
                        >
                            {
                              optionsFarm &&   optionsFarm.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Ano safra:&nbsp;</label>
                        <Field name="cropYear" as="select"
                               value={cropYear}
                               onChange={cropYearChangeHandler}
                        >
                            {
                                optionsCropYear.map(ele => {
                                    return (<option value={ele}>{ele}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Talhão:&nbsp;</label>
                        <Field name="optionsField" as="select"
                               value={field.id}
                               onChange={fieldChangeHandler}
                        >
                            {
                                optionsField.map(ele => {
                                    return (<option value={ele.id}>{ele.name}</option>);
                                })
                            }
                        </Field>
                    </div>

                          <div>
                        <label>Fertilidade:&nbsp;</label>
                        <Field name="optionsFertility" as="select"
                               value={fertilityLabel}
                               onChange={fertilityChangeHandler}
                        >
                            {
                                optionsFertility.map(ele => {
                                    return (<option value={ele}>{ele}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <div>
                        <label>Profundidade:&nbsp;</label>
                        <Field name="optionsCropDepth" as="select"
                               value={cropDepth}
                               onChange={optionsCropDepthChangeHandler}
                        >
                            {
                                optionsCropDepth.map(ele => {
                                    return (<option value={ele}>{ele}</option>);
                                })
                            }
                        </Field>
                    </div>
                    <br/>
                    <Divider></Divider>
                    { interpolation &&  <img src={interpolation.raster}/>}
                    <Divider></Divider>
                     <Box  height="10px"></Box>
                    <Flex>
                        <Box width="100px"></Box>
                        <Button  palette="danger" onClick={() => buttonBackChangeHandler() }> Voltar</Button>
                    </Flex>
                </form>
                )
            }
        ></Formik>
    );
};

export default PitFieldForm;